import _ from "lodash";
import * as Types from "../../constents/actionTypes";

const defaultState = {
  featureProductList: [],
  productDetail: {},
  productEulabels: {},
  productFeature: {},
  productImage: [],
  productTechnology: {},
  productpress: {},
  tireDetails: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.GET_FEATURE_PRODUCT:
      return {
        ...state,
        featureProductList: [...action.payload],
      };

    case Types.GET_PRODUCT_DETAIL: {
      return {
        ...state,
        productDetail: action.productDetail,
      };
    }
    case Types.GET_PRODUCT_IMAGE: {
      return {
        ...state,
        productImage: action.productImage,
        productLineImage: action.productLine
          ? action.productLine[0].image
          : null,
      };
    }
    case Types.GET_PRODUCT_TECH: {
      return {
        ...state,
        productTechnology: action.productTechnology,
      };
    }
    case Types.GET_PRODUCT_FEATURE: {
      return {
        ...state,
        productFeature: action.productFeature,
      };
    }
    case Types.GET_PRODUCT_CATEGORY: {
      return {
        ...state,
        productCategory: action.productCategory,
      };
    }
    case Types.GET_PRODUCT_EULABELS: {
      return {
        ...state,
        productEulabels: action.productEulabels,
      };
    }
    case Types.GET_PRODUCT_PRESS: {
      return {
        ...state,
        productpress: action.productpress,
      };
    }
    case Types.GET_PRODUCT_DETAIL_ALL_DATA: {
      return {
        ...state,
        productDetail: action.productDetail,
        productImage: action.productImage,
        productLineImage: action.productLine
          ? action.productLine[0].image
          : null,
        tireDetails: action.tireDetails,
        productTechnology: action.productTechnology,
        productFeature: action.productFeature,
        productEulabels: action.productEulabels,
        productpress: action.productpress,
        productCategory: action.productCategory,
      };
    }
    case Types.GET_TIRE_DETAIL: {
      return {
        ...state,
        tireDetails: action.tireDetails,
      };
    }
    case Types.GET_PROMO_LIST: {
      return {
        ...state,
        promoList: action.promoList,
      };
    }
    case Types.CLEARDATA: {
      return {
        ...state,
        productDetail: {},
        productEulabels: {},
        productFeature: {},
        productImage: [],
        productTechnology: {},
        productpress: {},
        tireDetails: {},
      };
    }
    case Types.UPDATE_FEATURE_PRODUCT_CART_QUANTITY: {
      const updateAddToCartQuantityItemListState = state.featureProductList;
      const updateAddToCartQuantityItemIndex =
        updateAddToCartQuantityItemListState.findIndex(
          (item) => parseInt(item.node.id) === parseInt(action.payload.itemId)
        );
      if (updateAddToCartQuantityItemIndex > -1) {
        let updateAddToCartQuantityItem =
          updateAddToCartQuantityItemListState[
            updateAddToCartQuantityItemIndex
          ];
        updateAddToCartQuantityItem.node.addToCartQuantity =
          action.payload.quantity;
        updateAddToCartQuantityItemListState[updateAddToCartQuantityItemIndex] =
          updateAddToCartQuantityItem;
      }
      return {
        ...state,
        featureProductList: _.cloneDeep(updateAddToCartQuantityItemListState),
      };
    }
    case Types.SAVE_FEEDBACK: {
      return {
        ...state,
        feedbackData: action.payload,
      };
    }
    case Types.GET_FEEFBACK_QUESTION: {
      return {
        ...state,
        feedbackQuestions: action.feedbackQuestion,
      };
    }
    case Types.CLEAR_PRODUCT_DATA: {
      return {
        ...state,
        featureProductList: [],
        productDetail: {},
        productEulabels: {},
        productFeature: {},
        productImage: [],
        productTechnology: {},
        productpress: {},
        tireDetails: {},
        feedbackQuestions: [],
      };
    }
    case Types.GET_FILTER_PRODUCT: {
      return {
        ...state,
        filterProductItem: action.filterProductItem,
      };
    }
    default:
      return state;
  }
};
