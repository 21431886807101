import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../store/actions/layout";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import logout from "../../assets/logout.png";
import { IconDashobard } from "../../Components/Icons";
class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubMenuOn: false,
    };
  }
  showSubMenu = () => {
    this.setState({
      isSubMenuOn: !this.state.isSubMenuOn,
    });
  };
  hidemenu = () => {
    this.props.showMenu();
    this.props.isLogout();
  };

  onLanguageChangeHandler = (lang) => (event) => {
    this.props.DEFAULT_LANGUAGE(lang);
    this.props.showMenu();
    this.props.changeLanguage(lang);
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { feedbackData, t } = this.props;
    if (feedbackData !== nextProps.feedbackData) {
      toast.success(t("tostMsg.feedbackmsg"));
    }
  }

  render() {
    const { t, feedbackQuestions, defaultLanguage } = this.props;
    return (
      <Fragment>
        <div
          className={this.props.isMenuOn ? "menu-box active" : "menu-box"}
          style={{
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          <div>
            <div className="mobile-nav-header">
              <div className="lang mobile-lang"></div>
              <h3 className="text-right" style={{ padding: "0px 32px" }}>
                <span
                  className="close icon-close"
                  onClick={this.props.showMenu}
                ></span>
              </h3>
            </div>

            <ul>
              <li>
                <Link to="/brand" onClick={this.props.showMenu}>
                  <span className="mr-3">
                    <IconDashobard w="17" />
                  </span>{" "}
                  {t("topHeader.dashboard")}
                </Link>
              </li>
              <li>
                <Link to="/brand" onClick={this.props.showMenu}>
                  <span className="icon-brand mr-3"></span>{" "}
                  {t("topHeader.brand")}
                </Link>
              </li>
              <li>
                <Link to="/products" onClick={this.props.showMenu}>
                  <span className="tires-tire mr-3"></span>{" "}
                  {t("topHeader.products")}
                </Link>
              </li>
              <li>
                <Link to="/notification" onClick={this.props.showMenu}>
                  <span className="icon-bell mr-3"></span>
                  {t("topHeader.notifications")}
                </Link>
              </li>
              <li>
                <Link to="/reservedstock" onClick={this.props.showMenu}>
                  <span className="icon-reserved-stock mr-3"></span>
                  {t("reserveStockTitle")}
                </Link>
              </li>
              <li>
                <Link to="/accounting" onClick={this.props.showMenu}>
                  <span className="icon-user mr-3"></span>
                  {t("topHeader.account")}
                </Link>
              </li>
              <li>
                <a href="tel:+972 74 723 23 00" onClick={this.props.showMenu}>
                  <span className="icon-phone mr-3"></span>
                  {t("topHeader.call")}
                </a>
              </li>
              {feedbackQuestions && feedbackQuestions.length > 0 && (
                <li>
                  <Link to="#!" onClick={this.props.giveFeedbackModalClose}>
                    {t("topHeader.feedback")}
                  </Link>
                </li>
              )}
              <li onClick={this.hidemenu} className="cursor_pointer">
                <span className="mr-3">
                  <img
                    alt="logout"
                    src={logout}
                    style={{ width: "16px" }}
                  ></img>
                </span>

                <Link to="#!">{t("topHeader.logout")}</Link>
              </li>
              {/* <li
                className={
                  this.state.isSubMenuOn ? "has-submenu on" : "has-submenu"
                }
              >
                <div onClick={this.showSubMenu}>
                  <span className="icon-user mr-3"></span>{" "}
                  {t("topHeader.account")}{" "}
                </div>
                {!this.state.isSubMenuOn && <ul></ul>}
              </li> */}
            </ul>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "0 32px",
              marginTop: "1rem",
            }}
          >
            <Button
              className={
                this.props.layout.defaultLanguage === "en"
                  ? "btn btn-primary"
                  : "btn"
              }
              onClick={this.onLanguageChangeHandler("en")}
            >
              English
            </Button>
            <Button
              className={
                this.props.layout.defaultLanguage === "he"
                  ? "btn btn-primary"
                  : "btn"
              }
              onClick={this.onLanguageChangeHandler("he")}
            >
              Hebrew
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    feedbackData: state.Product.feedbackData,
    feedbackQuestions: state.Product.feedbackQuestions,
    defaultLanguage: state.Layout.defaultLanguage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    DEFAULT_LANGUAGE: (payload) => dispatch(DEFAULT_LANGUAGE(payload)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Navigation)
);
