import React, { Component } from "react";
import { Label, FormGroup, Input, Button, Modal, ModalBody } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { VERIFY_USER_EMAIL, RESEND_USER_EMAIL } from "../../store/actions/auth";
import { toast } from "react-toastify";

const mapStateToProps = (state) => {
  return {
    userverifyed: state.Auth.userverifyed,
    userverifyedFail: state.Auth.userverifyedFail,
    email: state.Auth.email,
    resendverifyed: state.Auth.resendverifyed,
    lang: state.Layout.defaultLanguage,
  };
};

const mapDispatchToProps = {
  VERIFY_USER_EMAIL: (email, lang) => VERIFY_USER_EMAIL(email, lang),
  RESEND_USER_EMAIL: (email, lang) => RESEND_USER_EMAIL(email, lang),
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSend: false,
      email: "",
      errorClass: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { userverifyed, userverifyedFail, resendverifyed } = this.props;
    if (resendverifyed !== nextProps.resendverifyed) {
      toast.success("Resend Mail Successfuly.");
    }
    if (userverifyed !== nextProps.userverifyed) {
      this.setState({
        mailSend: true,
      });
    }
    if (userverifyedFail !== nextProps.userverifyedFail) {
      this.setState({
        errorClass: true,
      });
    }
  }
  //Added if condition to check the email validation
  sendMail = () => {
    const { email } = this.state;
    const { lang } = this.props;
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      ) ||
      /[0-9]-?\d{3}-?\d{4}$/gm.test(email)
    ) {
      this.props.VERIFY_USER_EMAIL(email, lang);
    } else {
      this.setState({
        errorClass: true,
      });
    }
  };

  resendMail = () => {
    const { email } = this.state;

    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      ) ||
      /[0-9]-?\d{3}-?\d{4}$/gm.test(email)
    ) {
      this.props.RESEND_USER_EMAIL(email, this.props.lang);
    } else {
      this.setState({
        errorClass: true,
      });
    }
  };
  //added a function to display the email in input field
  onEmailEnter = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handalKeypress = (e) => {
    if (e.key === "Enter") {
      this.sendMail();
    }
  };

  onEmailKeyUp = (event) => {
    const { email } = this.state;
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      ) ||
      /[0-9]-?\d{3}-?\d{4}$/gm.test(email)
    )
      this.setState({
        errorClass: false,
      });
    else
      this.setState({
        errorClass: true,
      });

    if (event.target.value === "") this.setState({ errorClass: false });
  };
  closeModal = () => {
    this.setState({
      mailSend: false,
      email: "",
      errorClass: false,
    });
    this.props.onForgotPassword();
  };
  render() {
    const { t, forgotpassword, userverifyedFail, email } = this.props;

    return (
      <Modal isOpen={forgotpassword} className="forgotpassword">
        <div className="modal-close" onClick={this.props.onForgotPassword}>
          <span className="icon-close"></span>
        </div>
        {!this.state.mailSend ? (
          <ModalBody>
            <h3 className="text-center mb-4">{t("forgotCard.title")}</h3>
            <p className="text-center">{t("forgotCard.subtitle")}</p>
            <FormGroup className="mt-4">
              <div className="form-label-group text-left">
                {/* Added value and onchange attribute to check the email validation */}

                <Input
                  type="text"
                  id="email"
                  name="email"
                  placeholder={t("forgotCard.email")}
                  value={this.state.email}
                  onChange={this.onEmailEnter}
                  onKeyUp={this.onEmailKeyUp}
                  onKeyPress={this.handalKeypress}
                  required
                  autoFocus
                />
                <Label for="email">{t("forgotCard.email")}</Label>

                {/* Added a div to give error message */}
                {userverifyedFail ? (
                  <div
                    className="email-error text-lowercase"
                    htmlFor="email"
                    dangerouslySetInnerHTML={{ __html: userverifyedFail }}
                  ></div>
                ) : (
                  this.state.errorClass && (
                    <div
                      className="email-error"
                      htmlFor="email"
                      dangerouslySetInnerHTML={{
                        __html: t("tostMsg.validEmail"),
                      }}
                    ></div>
                  )
                )}
                {/* Changes made to give error message END */}
              </div>
            </FormGroup>
            <FormGroup>
              <Button
                color="primary"
                className="rounded-pill my-4"
                block
                onClick={this.sendMail}
              >
                {t("forgotCard.cta")}
                <span className="icon-next"></span>
              </Button>
            </FormGroup>
            <FormGroup>
              <a
                className="text-underline"
                href="#"
                onClick={this.props.onForgotPassword}
              >
                {t("forgotCard.cta2")}
              </a>
            </FormGroup>
          </ModalBody>
        ) : (
          <ModalBody>
            <h3 className="text-center mb-4">{t("ResetCard.title")}</h3>

            <p className="text-center text-muted">{email}</p>

            <p className="text-center">{t("ResetCard.subtitle")}</p>

            <FormGroup>
              <Button
                color="primary"
                className="rounded-pill"
                block
                onClick={this.closeModal}
              >
                {t("ResetCard.cta")}
              </Button>
            </FormGroup>
            <FormGroup>
              {t("ResetCard.resendMail")}{" "}
              <a href="#" onClick={this.resendMail}>
                {t("ResetCard.cta2")}{" "}
              </a>
            </FormGroup>
          </ModalBody>
        )}
      </Modal>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
