import * as Types from "../../constents/actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case Types.SET_TOKEN:
      return {
        ...state,
        token: action.userId,
        isUserAuthenticated: true,
        userData: action.userData,
      };

    case Types.AUTH_LOGIN_ERROR: {
      return {
        ...state,
        authLoginError: action.authLoginError,
      };
    }
    case Types.IS_USER_AUTHENTICATED: {
      return {
        ...state,
        isUserAuthenticated: action.isUserAuthenticated,
        userId: action.userId,
        userData: action.userData,
      };
    }
    case Types.ON_LOGOUT: {
      delete state.refreshToken;
      delete state.token;
      return {
        ...state,
        isUserAuthenticated: false,
        userId: undefined,
        userData: undefined,
      };
    }
    case Types.VERIFY_USER_EMAIL: {
      return {
        ...state,
        userverifyed: action.userverifyed,
        email: action.email,
      };
    }
    case Types.RESET_USER_EMAIL: {
      return {
        ...state,
        resendverifyed: action.resendverifyed,
      };
    }
    case Types.VERIFY_USER_EMAIL_ERROR: {
      return {
        ...state,
        userverifyedFail: action.error,
      };
    }
    case Types.CLEAR_AUTH_DATA: {
      return {
        ...state,
        isUserAuthenticated: false,
        userId: undefined,
        userData: undefined,
      };
    }
    default:
      return state;
  }
};
