import React from "react";

const loader = () => {
  return (
    <div className="no-freeze-spinner">
      <div id="no-freeze-spinner">
        <div>
          <i className="material-icons">
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="AccountCircleIcon"
              tabIndex="-1"
              title="AccountCircle"
            >
              <path
                fill="white"
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z"
              ></path>
            </svg>
          </i>
          <i className="material-icons">
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="HomeIcon"
              tabIndex="-1"
              title="Home"
            >
              <path fill="white" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>
            </svg>
          </i>
          <i className="material-icons">
            <svg
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="WorkIcon"
              tabIndex="-1"
              title="Work"
            >
              <path
                fill="white"
                d="M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2z"
              ></path>
            </svg>
          </i>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default loader;
