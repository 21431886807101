import axios from "axios";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import * as Types from "../../constents/actionTypes";
import {
  API_REQUESTS,
  API_URL,
  IMPORT_URL,
  PARENTS_ID,
} from "../../constents/helpers";
import Axios from "../../models/Axios";
import {
  CREATE_CART_ITEM_MUTATION,
  CREATE_ORDER_QUERY,
  GET_CART_DETAIL_QUERY,
  GET_USER_CART_ID_AND_FEATURED_PRODUCTS_QUERY,
  GET_USER_CART_INFO_AND_FEATURED_PRODUCTS_QUERY,
  UPDATE_CART_MUTATION,
} from "../queries/cart";
import { sentryCustomCaptureException } from "../../utils/custom-capture-exception";

export const getUserCart = async (lang) => {
  const response = await Axios.axios({
    method: "get",
    url: `${API_URL}api/v1/cart?lang=${lang}`,
  });
  return response;
};

export const GET_CART_DETAIL = (_id, lang) => async (dispatch) => {
  try {
    const response = await getUserCart(lang);
    dispatch({
      type: Types.GET_CART_DETAIL,
      itemIncart:
        response.data.cart?.[0]?.cartItem.map((item) => {
          return {
            ...item,
            specialPrice:
              item.tireSize.specialPrice.length === 0
                ? null
                : item.tireSize.specialPrice,
            tireSize: {
              ...item.tireSize,
              voucherPromotion: item.tireSize.voucherPromotion
                ? item.tireSize.voucherPromotion
                : [],
              tire: [
                {
                  ...item.tireSize.tire[0],
                  certifiedBy:
                    item.tireSize.tire[0].certifiedBy.length === 0
                      ? null
                      : item.tireSize.tire[0].certifiedBy,
                },
              ],
            },
          };
        }) ?? [],
    });
    return response;
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_CART_DETAIL_ERROR, error });
  }
};

export const UPDATE_RESERVED_STOCK = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      query
    );
    dispatch({
      type: Types.UPDATE_RESERVED_STOCK,
      payload: response.data,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.UPDATE_RESERVED_STOCK_ERROR, error });
  }
};

export const CREATE_CART = (payload) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}api/v1/cart`,
      data: {
        totalAmount: 0,
        cartItem: [],
      },
    });
    dispatch({
      type: Types.CREATE_CART,
      createdCartDetails: response.data.data.createCart.output,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.CREATE_CART_ERROR, error });
  }
};

// export const CREATE_CARTITEM = (query)=>async (dispatch)=>{
//   try{
//       const response = await Axios.axios.post(`${API_URL}${API_REQUESTS.PRODUCTS}`, query)
//     dispatch({
//       type: Types.CREATE_CARTITEM,
//       updateCartDetails : response.data.data
//       })

//     }catch(error){
//       dispatch({
//         type:Types.CREATE_CARTITEM_ERROR, error
//       })
//     }
// }

export const UPDATE_CART = (query) => async (dispatch) => {
  try {
    //   const response = await Axios.axios({
    //     method: 'post',
    //     url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
    //     data: {query:query}

    // })
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.PRODUCTS}`,
      query
    );
    dispatch({
      type: Types.UPDATE_CART,
      updateCartDetails: response.data.data.updateCart.output.modificationDate,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.UPDATE_CART_ERROR,
      error,
    });
  }
};

// export const UPDATE_CART_NEW = (query)=>async (dispatch)=>{
//   try{
//     const response = await Axios.axios.post(`${API_URL}${API_REQUESTS.PRODUCTS}`, query)

//   dispatch({
//     type: Types.UPDATE_CART,
//     updateCartDetails : response.data.data
//     })

//   }catch(error){
//     dispatch({
//       type:Types.UPDATE_CART_ERROR, error
//     })
//   }
// }

export const LIST_PRODUCT = (payload) => async (dispatch) => {
  const { lang, selectedFilters } = payload;
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}filtersearch/?limit=15&offset=0&lang=${lang}&orderkey=stock&order=desc&onlyForSale=true`,
    });
    if (response) {
      dispatch({
        type: Types.LIST_PRODUCT,
        listProducts: response.data.tireSize,
        selectedFilters: selectedFilters,
        morelistEnd: false,
      });
    } else {
      dispatch({ type: Types.LIST_PRODUCT_ERROR, error: "No Product Found" });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.LIST_PRODUCT_ERROR, error });
  }
};

export const LOADMORE_PRODUCT = (payload) => async (dispatch) => {
  try {
    const { offset, selectedFilters, lang, userId } = payload;
    let response = [];
    let filters = ``;
    if (selectedFilters) {
      const {
        width,
        height,
        inch,
        segment,
        load,
        speed,
        brand,
        voucherPromotion,
        orderkey,
        order,
        searchData,
        Passenger,
        Trucks,
        Others,
      } = selectedFilters;
      if (offset) {
        filters = filters += `&offset=${offset}`;
      }
      if (searchData) {
        filters = filters += `&filter=${searchData}`;
      }
      if (width) {
        filters = filters += `&width=${width}`;
      }
      if (height) {
        filters = filters += `&height=${height}`;
      }
      if (inch) {
        filters = filters += `&inch=${inch}`;
      }
      if (segment) {
        filters = filters += `&segment=${segment}`;
      }
      if (load) {
        filters = filters += `&load=${load}`;
      }
      if (speed) {
        filters = filters += `&speed=${speed}`;
      }
      if (brand) {
        filters = filters += `&brand=${brand}`;
      }
      if (voucherPromotion) {
        if (voucherPromotion === 2) {
          filters = filters += `&promotion=0`;
        } else {
          filters = filters += `&promotion=1`;
        }
      }
      if (!voucherPromotion && orderkey) {
        filters = filters += `&orderkey=${orderkey}`;
      }
      if (!voucherPromotion && order) {
        filters = filters += `&order=${order}`;
      }
      if (Passenger || Trucks || Others) {
        let othersegment;

        if (Passenger) {
          othersegment = `&othersegment=1`;
        } else if (Trucks) {
          othersegment = `&othersegment=2`;
        } else if (Others) {
          othersegment = `&othersegment=3`;
        }

        if (Passenger && Trucks) {
          othersegment = `&othersegment=1,2`;
        } else if (Passenger && Others) {
          othersegment = `&othersegment=1,3`;
        } else if (Trucks && Others) {
          othersegment = `&othersegment=2,3`;
        }

        if (Passenger && Trucks && Others) {
          othersegment = `&othersegment=1,2,3`;
        }
        filters = filters += othersegment;
      }
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=15&userId=${userId}&lang=${lang}${filters}`,
      });
    } else {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=15&userId=${userId}&lang=${lang}&offset=${offset}&orderkey=stock&order=desc`,
      });
    }
    if (
      response.data &&
      response.data.tireSize &&
      response.data.tireSize.length > 0
    ) {
      dispatch({
        type: Types.LOADMORE_PRODUCT,
        moreProducts: response.data.tireSize,
      });
    } else {
      dispatch({
        type: Types.LIST_PRODUCT_ERROR,
        error: "No More Product Found",
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.LIST_PRODUCT_ERROR, error });
  }
};

export const LOADMORE_PRODUCT_WITH_SEARCH = (payload) => async (dispatch) => {
  try {
    const { offset, searchData, lang, userId } = payload;
    let response = [];
    let filters = ``;
    if (searchData) {
      if (offset) {
        filters = filters += `&offset=${offset}`;
      }
      if (searchData) {
        filters = filters += `&filter=${searchData}`;
      }
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=15&userId=${userId}&lang=${lang}${filters}`,
      });
    } else {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=15&userId=${userId}&lang=${lang}&offset=${offset}&orderkey=stock&order=desc`,
      });
    }
    if (
      response.data &&
      response.data.tireSize &&
      response.data.tireSize.length > 0
    ) {
      dispatch({
        type: Types.LOADMORE_PRODUCT_WITH_SEARCH,
        moreProducts2: response.data.tireSize,
      });
    } else {
      dispatch({
        type: Types.LIST_PRODUCT_ERROR,
        error: "No More Product Found",
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.LIST_PRODUCT_ERROR, error });
  }
};

export const GET_FILTER_OPTIONS = (lang) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}filterdata/?lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.FILTER_OPTIONS,
        filterOptions: response.data.tireParameters,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.FILTER_OPTIONS_ERROR, error });
  }
};
export const UPDATE_FILTER_OPTIONS = (payload) => async (dispatch) => {
  try {
    const {
      searchData,
      width,
      height,
      inch,
      load,
      speed,
      brand,
      segment,
      lang,
      Passenger,
      Trucks,
      Others,
    } = payload;
    let response = [];
    let filters = ``;
    if (payload) {
      if (searchData) {
        filters = filters += `filter=${searchData}&`;
      }
      if (lang) {
        filters = filters += `lang=${lang}`;
      }
      if (width) {
        filters = filters += `&width=${width}`;
      }
      if (height) {
        filters = filters += `&height=${height}`;
      }
      if (inch) {
        filters = filters += `&inch=${inch}`;
      }
      if (segment) {
        filters = filters += `&segment=${segment}`;
      }
      if (load) {
        filters = filters += `&load=${load}`;
      }
      if (speed) {
        filters = filters += `&speed=${speed}`;
      }
      if (brand) {
        filters = filters += `&brand=${brand}`;
      }
      if (Passenger || Trucks || Others) {
        let othersegment;
        if (Passenger) {
          othersegment = `&othersegment=1`;
        } else if (Trucks) {
          othersegment = `&othersegment=2`;
        } else if (Others) {
          othersegment = `&othersegment=3`;
        }

        if (Passenger && Trucks) {
          othersegment = `&othersegment=1,2`;
        } else if (Passenger && Others) {
          othersegment = `&othersegment=1,3`;
        } else if (Trucks && Others) {
          othersegment = `&othersegment=2,3`;
        }

        if (Passenger && Trucks && Others) {
          othersegment = `&othersegment=1,2,3`;
        }
        filters = filters += othersegment;
      }
    }
    response = await Axios.axios.get(`${API_URL}filterdata/?${filters}`);
    if (response) {
      dispatch({
        type: Types.UPDATE_FILTER_OPTIONS,
        updatedfilterOptions: response.data.tireParameters,
        selectedFilters: payload,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.FILTER_OPTIONS_ERROR,
      error,
    });
  }
};
export const ADD_RECENT_VIEW = (tireSize) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(`${API_URL}api/v1/recent-views`, {
      tireSize,
    });
    if (response) {
      dispatch({
        type: Types.ADD_RECENT_VIEW,
        recentAdded: response.data.id,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.ADD_RECENT_VIEW_ERROR, error });
  }
};
export const GET_RECENT_VIEW = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}api/v1/recent-views`,
      query
    );
    if (response) {
      dispatch({
        type: Types.GET_RECENT_VIEW,
        recentView: response.data.data.getUser.recentView,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_RECENT_VIEW_ERROR, error });
  }
};
export const GET_RECENT_VIEW_RESTAPI =
  ({ userId, lang, limit = 30, offset = 0 }) =>
  async (dispatch) => {
    try {
      const response = await Axios.axios.get(
        `${API_URL}api/v1/users/recent-view?lang=${lang}&userId=${userId}&limit=${limit}&offset=${offset}`
      );
      if (response) {
        dispatch({
          type: Types.GET_RECENT_VIEW,
          recentView: response.data.recentView,
        });
      }
    } catch (error) {
      sentryCustomCaptureException(error);
      dispatch({ type: Types.GET_RECENT_VIEW_ERROR, error });
    }
  };

export const FILTER_PRODUCT = (payload) => async (dispatch) => {
  try {
    const {
      width,
      height,
      inch,
      segment,
      load,
      speed,
      brand,
      voucherPromotion,
      orderkey,
      order,
      searchData,
      lang,
      Passenger,
      Trucks,
      Others,
      userId,
    } = payload;
    let response = [];
    let filters = ``;
    if (payload) {
      if (userId) {
        filters = filters += `userId=${userId}`;
      }
      if (searchData) {
        filters = filters += `&filter=${searchData}`;
      }
      if (lang) {
        filters = filters += `&lang=${lang}`;
      }
      if (width) {
        filters = filters += `&width=${width}`;
      }
      if (height) {
        filters = filters += `&height=${height}`;
      }
      if (inch) {
        filters = filters += `&inch=${inch}`;
      }
      if (segment) {
        filters = filters += `&segment=${segment}`;
      }
      if (load) {
        filters = filters += `&load=${load}`;
      }
      if (speed) {
        filters = filters += `&speed=${speed}`;
      }
      if (brand) {
        filters = filters += `&brand=${brand}`;
      }
      if (voucherPromotion) {
        if (voucherPromotion === 2) {
          filters = filters += `&promotion=0`;
        } else {
          filters = filters += `&promotion=1`;
        }
      }
      if (!voucherPromotion && orderkey) {
        filters = filters += `&orderkey=${orderkey}`;
      }
      if (!voucherPromotion && order) {
        filters = filters += `&order=${order}`;
      }
      if (Passenger || Trucks || Others) {
        let othersegment;

        if (Passenger) {
          othersegment = `&othersegment=1`;
        } else if (Trucks) {
          othersegment = `&othersegment=2`;
        } else if (Others) {
          othersegment = `&othersegment=3`;
        }

        if (Passenger && Trucks) {
          othersegment = `&othersegment=1,2`;
        } else if (Passenger && Others) {
          othersegment = `&othersegment=1,3`;
        } else if (Trucks && Others) {
          othersegment = `&othersegment=2,3`;
        }

        if (Passenger && Trucks && Others) {
          othersegment = `&othersegment=1,2,3`;
        }
        filters = filters += othersegment;
      }
      // response = await Axios.axios({
      //   method: 'get',
      //   url: `${API_URL}filtersearch/?${filters}`,
      // })
    }
    // else {
    //   response = await Axios.axios({
    //     method: 'get',
    //     url: `${API_URL}filtersearch/?limit=15&lang=${lang}&offset=0&orderkey=stock&userId=${userId}&order=desc`,
    //   })
    // }

    if (
      filters &&
      (width ||
        height ||
        inch ||
        segment ||
        load ||
        speed ||
        brand ||
        voucherPromotion ||
        searchData ||
        Passenger ||
        Trucks ||
        Others ||
        orderkey)
    ) {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?${filters}&limit=15&onlyForSale=true`,
      });
    } else {
      response = await Axios.axios({
        method: "get",
        url: `${API_URL}filtersearch/?onlyForSale=true&limit=15&lang=${lang}&offset=0&orderkey=stock&userId=${userId}&order=desc`,
      });
    }

    if (response) {
      dispatch({
        type: Types.FILTER_PRODUCT,
        filterList: response.data.tireSize,
        selectedFilters: payload,
      });
    } else {
      dispatch({
        type: Types.FILTER_PRODUCT_ERROR,
        morelistEnd: true,
        morelistMsg: "No Product Found",
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.FILTER_PRODUCT_ERROR, error });
  }
};
export const CLEAR_ERROR = () => async (dispatch) => {
  dispatch({
    type: Types.FILTER_PRODUCT_ERROR,
    morelistEnd: false,
    morelistMsg: null,
  });
};
export const REMOVE_SEARCH = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_FILTERS,
    searchData: undefined,
  });
};

export const SEARCH_PRODUCTS = (payload) => async (dispatch) => {
  try {
    const { searchFilter, lang, userId } = payload;
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}filtersearch/?onlyForSale=true&limit=15&lang=${lang}&offset=0&userId=${userId}&filter=${searchFilter}`,
    });
    if (response) {
      dispatch({
        type: Types.SEARCH_PRODUCTS,
        searchedProductsList: response.data.tireSize ?? [],
        searchData: payload.searchFilter,
      });
    } else {
      dispatch({
        type: Types.SEARCH_PRODUCTS_ERROR,
        error: "No Product Found",
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.SEARCH_PRODUCTS_ERROR, error });
  }
};

export const CONFIRM_CALLOFF = (payload) => async (dispatch) => {
  try {
    let response = [];
    for (let i = 0; i < payload.length; i++) {
      let totalQty = 0;

      let orderKey = 0;
      await Axios.axios
        .get(`${API_URL}generateorderitemnumber/`)
        .then((response) => {
          orderKey = response.data.orderitemid;
        });

      let CONFIRM_CALLOFF_QUERY = {
        query: ` mutation updateOrderItem($ItemId:Int!, $quantity:Int!, $id:Int!, $price:Float!, $totalPrice:Float!,  $reservedStockCallOff: Int!,$discountAmount:Float!, $totalPriceAfterDiscount:Float!){
          updateOrderItem(id: $ItemId, input: {quantity: $quantity, tireSize: {id: $id, type: "object"}, price: $price, totalPrice: $totalPrice, discountAmount: $discountAmount, totalPriceAfterDiscount: $totalPriceAfterDiscount, reservedStockCallOff: {id: $reservedStockCallOff, type: "object"}, isCallOff: true}) {
          success
          message
          output(defaultLanguage: "en") {
            id
            key
          }
        }
      }`,
        variables: {
          ItemId: orderKey,
          quantity: totalQty,
          id: parseInt(payload[i].tireSize.id),
          price: 0,
          totalPrice: 0,
          reservedStockCallOff: parseInt(payload[i].reservedStock.id), // Pass the reserve stock if there; if there is no reserve stock pass "NULL"
          discountAmount: 0,
          totalPriceAfterDiscount: 0,
        },
      };

      let createItem = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        CONFIRM_CALLOFF_QUERY
      );
      response.push(createItem.data.data.updateOrderItem.output);
    }
    // for Loop Ends
    if (response) {
      dispatch({
        type: Types.CONFIRM_CALLOFF,
        payload: response,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.CONFIRM_CALLOFF_ERROR,
      error,
    });
  }
};

export const CREATE_ORDER_STATUS = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.CREATE_ORDER_STATUS,
        orderStatus: undefined,
      });
    } else {
      let response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        query
      );
      if (response) {
        dispatch({
          type: Types.CREATE_ORDER_STATUS,
          orderStatus: response.data.data.createOrderStatus.output,
        });
      }
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.STATUS_ERROR,
      error,
    });
  }
};

export const CREATE_INVOICE = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.CREATE_INVOICE,
        invoiceStatus: undefined,
      });
    } else {
      let response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        query
      );
      if (response) {
        dispatch({
          type: Types.CREATE_INVOICE,
          invoiceStatus: response.data.data.updateInvoice.output,
        });
      }
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.CREATE_INVOICE_ERROR,
      error,
    });
  }
};

export const PROMO_VALIDATE = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.PROMO_VALIDATE,
        payload: undefined,
      });
    } else {
      let response = await Axios.axios.post(
        `${API_URL}${API_REQUESTS.USER}`,
        query
      );
      if (response) {
        dispatch({
          type: Types.PROMO_VALIDATE,
          promoDetails:
            response.data.data.getPromotionCodeListing.edges[0].node,
        });
      }
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.PROMO_VALIDATE_ERROR,
      error,
    });
  }
};

export const CREATE_ORDER = (query) => async (dispatch) => {
  try {
    let response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      query
    );
    if (response) {
      dispatch({
        type: Types.CREATE_ORDER,
        orderDetails: response.data.data.updateOrder.output,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.CREATE_ORDER_ERROR,
      error,
    });
  }
};

export const SEND_MAIL = (payload) => async (dispatch) => {
  try {
    if (payload === "reset") {
      dispatch({
        type: Types.SEND_MAIL,
        payload: undefined,
      });
    } else {
      const {
        email,
        orderId,
        errorMessage,
        phoneNumber,
        defaultCommunication,
      } = payload;
      const response = await Axios.axios.get(
        `${API_URL}notifyorderviaemail/?email=${email}&orderId=${orderId}&phone=${phoneNumber}&defaultCommunication=${defaultCommunication}&errorMessage=${errorMessage}`
      );
      dispatch({
        type: Types.SEND_MAIL,
        payload: response.data.status,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.SEND_MAIL_ERROR,
      error,
    });
  }
};

export const DELETE_ITEM = (id) => async (dispatch) => {
  try {
    await Axios.axios.delete(`${API_URL}api/v1/cart-item/${id}`);
    dispatch({
      type: Types.DELETE_ITEM,
      payload: {},
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.DELETE_ITEM_ERROR,
      error,
    });
  }
};

export const UPDATE_ADD_TO_CART_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_ADD_TO_CART_QUANTITY,
      payload: { itemId, quantity },
    });
  };

export const UPDATE_RESERVED_STOCK_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_RESERVED_STOCK_QUANTITY,
      payload: { itemId, quantity },
    });
  };
export const UPDATE_RECENTLY_VIEWED_PRODUCT_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_RECENTLY_VIEWED_PRODUCT_QUANTITY,
      payload: { itemId, quantity },
    });
  };

// export const UPDATE_CART_ITEM = (id, quantity, lang, item, quantityFromReservedStock) => async dispatch => {
//   try {
//     let UPDATE_CART_QUERY
//     if(item && quantityFromReservedStock){
//       const reservedStockId = item.reservedStock.id
//       UPDATE_CART_QUERY = {
//         query: `
//             mutation {
//                 updateCartItem (
//                 id: ${id}
//                 input: {
//                     quantity: ${quantity},
//                     quantityFromReservedStock:${quantityFromReservedStock},
//                     reservedStock : {id: ${reservedStockId}, type: "object" }
//                 }
//                 ) {
//                 success
//                     message
//                     output(defaultLanguage: "en") {
//                     id
//                     key
//                     modificationDate
//                     }
//                 }
//             }
//         `
//       }
//     }else{
//       UPDATE_CART_QUERY = {
//         query: `
//             mutation {
//                 updateCartItem (
//                 id: ${id}
//                 input: {
//                     quantity: ${quantity}
//                 }
//                 ) {
//                 success
//                     message
//                     output(defaultLanguage: "en") {
//                     id
//                     key
//                     modificationDate
//                     }
//                 }
//             }
//         `
//       }
//     }

//
//     //     await Axios.axios.post(`${API_URL}${API_REQUESTS.PRODUCTS}`, UPDATE_CART_QUERY)

//     const cartId = JSON.parse(atob(sessionStorage.getItem('cartId')))
//     const GET_CART_DETAIL_QUERY = {
//       query: `{getCart(id: ${cartId}, defaultLanguage: "${lang}") {id user {... on object_user {id userGroup {... on object_userGroup {id groupType}}}} cartItem {... on object_cartItem {id quantity specialPrice{ ... on object_specialPrice { id specialPrice validFrom validTo}} tireSize {... on object_tireSize {id width height diameter contructionType loadRating speedRating price stock outofStockMessage validFrom validUntil specialPrice { ... on object_specialPrice { specialPrice userGroup { ... on object_userGroup {id groupType}}}}tire {... on object_tire {id title segment {... on object_segment {name}}brand {... on object_brand {brandName}}certifiedBy {... on object_certifiedBy {tooltip icon { fullpath}}}}}promotion {... on object_promotion {id minPurchaseType minPurchaseAmount discountType discountTypeValue freeShipping includeCheapestProductFree freeProductQuantity maxDiscountAmount freeTire { ... on object_freeTire { id stock tire { ... on object_tireSize { id width height diameter contructionType loadRating speedRating tire { ... on object_tire { title }}}}}}providePromotionCode promotionCodeValidDays promotionCodeValidDaysValue promotionCodeValidDate promotionCodeValidDateValue validFrom validUntil title userGroup {... on object_userGroup {id groupType }}}}}}}}}}`
//     }
//     const response = await Axios.axios({
//       method: 'post',
//       url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
//       data: GET_CART_DETAIL_QUERY

//     })
//     if (response) {
//       dispatch({
//         type: Types.UPDATE_CART_ITEM,
//         itemIncart: response.data.data.getCart.cartItem
//       })
//     }

//   } catch (error) {
//     dispatch({
//       type: Types.UPDATE_CART_ERROR, error
//     })
//   }
// }

const getSpecialPrice = (productDetail, userId) => {
  let todayDate = moment();
  //check if date is valid | BASIS is if date is future date it is valid

  //first filter | Based on date
  const isDateValid = (dateToCheck) => {
    return moment.unix(dateToCheck) > todayDate;
  };
  const dateFilteredData =
    productDetail &&
    productDetail.specialPrice &&
    productDetail.specialPrice.length &&
    productDetail.specialPrice.filter((item) => {
      return isDateValid(item.validTo);
    });

  //second filter | Based on whether it contains logged in user
  const userFilteredData =
    dateFilteredData &&
    dateFilteredData.length &&
    dateFilteredData.filter((item) => {
      return true;
    });

  return userFilteredData;
};

export const UPDATE_CART_ITEM =
  (id, quantity, lang, cartItems, item, userId) => async (dispatch) => {
    // if(cartItems === undefined || cartItems === null) return;
    let cartItems =
      (await Axios.axios
        .get(`${API_URL}api/v1/cart`)
        .then((res) => res.data.cart?.[0]?.cartItem)) ?? [];

    try {
      if (window._paq) {
        window._paq.push(["clearEcommerceCart"]);
      }

      cartItems = cartItems.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity,
          };
        }
        return item;
      });

      let updateCartTotalPrice = 0;
      cartItems.forEach((item) => {
        const price = item.specialPrice
          ? item.specialPrice.specialPrice
          : item.tireSize.price;
        updateCartTotalPrice = updateCartTotalPrice += item.quantity * price;
      });

      cartItems = [
        ...cartItems.map((item) => {
          return {
            id: parseInt(item.id),
            quantity: item.quantity,
            specialPriceId: item.specialPrice?.id
              ? parseInt(item.specialPrice?.id)
              : null,
            tireSize: parseInt(item.tireSize.id),
          };
        }),
      ];

      const cartId = JSON.parse(atob(sessionStorage.getItem("cartId")));
      const response = await Axios.axios({
        method: "put",
        url: `${API_URL}api/v1/cart/${cartId}?lang=${lang}`,
        data: {
          totalAmount: updateCartTotalPrice,
          cartItem: cartItems,
        },
      });

      const updatedCartData = await Axios.axios({
        method: "get",
        url: `${API_URL}api/v1/cart?lang=${lang}`,
      });

      if (response && updatedCartData) {
        const maybeCart = updatedCartData.data.cart.find(
          (cart) => cart.id.toString() === cartId.toString()
        );
        if (maybeCart) {
          if (window._paq) {
            for (let i = 0; i < maybeCart.cartItem.length; i++) {
              const tireSize = maybeCart.cartItem[i].tireSize;
              const price = maybeCart.cartItem[i].specialPrice
                ? maybeCart.cartItem[i].specialPrice.specialPrice
                : tireSize.price;

              const productName = `${tireSize.tire[0].brand.brandName} ${
                tireSize.tire[0].title
              }${
                tireSize.tire[0].productCode
                  ? ` [${tireSize.tire[0].productCode}]`
                  : ""
              }`;

              window._paq.push([
                "addEcommerceItem",
                tireSize.tire[0].productCode
                  ? tireSize.tire[0].productCode
                  : tireSize.id,
                productName,
                "Tire",
                price,
                maybeCart.cartItem[i].quantity,
              ]);
            }

            window._paq.push([
              "trackEcommerceCartUpdate",
              updateCartTotalPrice,
            ]);
          }

          dispatch({
            type: Types.UPDATE_CART_ITEM,
            itemiId: id,
            updatedQuantity: quantity,
            itemIncart: maybeCart.cartItem,
          });
        }
      }
    } catch (error) {
      sentryCustomCaptureException(error);
      dispatch({
        type: Types.UPDATE_CART_ERROR,
        error,
      });
    }
  };

export const CLEAR_UPDATEDITEM = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_UPDATEDITEM,
    payload: null,
  });
};

export const GET_USER_CART_ID = (id) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(`${API_URL}api/v1/cart`);
    if (response) {
      dispatch({
        type: Types.GET_USER_CART_ID,
        payload: response.data.cart,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
  }
};

export const GET_USER_CART_ID_AND_FEATURED_PRODUCTS =
  (id, lang) => async (dispatch) => {
    try {
      const date = new Date();
      let currentDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let currentTime =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

      const featuredProductsResponse = await Axios.axios.get(
        `${API_URL}api/v1/tire-sizes/featured?showasFeaturedProduct=1&validFrom=${currentDate} ${currentTime}&_operatorValidFrom=<=&validUntil=${currentDate} ${currentTime}&_operatorValidUntil=>=&lang=${lang}`
      );
      const userCartResponse = await Axios.axios.get(`${API_URL}api/v1/cart`);

      dispatch({
        type: Types.GET_USER_CART_ID,
        payload: userCartResponse.data.cart,
      });
      dispatch({
        type: Types.GET_FEATURE_PRODUCT,
        payload: featuredProductsResponse.data.tireSizes.map((tireSize) => {
          return {
            ...tireSize,
            tire: [
              {
                ...tireSize.tire[0],
                certifiedBy:
                  tireSize.tire[0].certifiedBy.length > 0
                    ? tireSize.tire[0].certifiedBy
                    : null,
              },
            ],
          };
        }),
      });
    } catch (error) {
      sentryCustomCaptureException(error);
    }
  };

export const GET_USER_CART_INFO_AND_FEATURED_PRODUCTS =
  (id, lang) => async (dispatch) => {
    try {
      const date = new Date();
      let currentDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let currentTime =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

      const featuredProductsResponse = await Axios.axios.get(
        `${API_URL}api/v1/tire-sizes/featured?showasFeaturedProduct=1&validFrom=${currentDate} ${currentTime}&_operatorValidFrom=<=&validUntil=${currentDate} ${currentTime}&_operatorValidUntil=>=&lang=${lang}`
      );
      const userCartResponse = await Axios.axios.get(`${API_URL}api/v1/cart`);
      const cartInfoResponse = await Axios.axios({
        method: "get",
        url: `${API_URL}api/v1/cart?lang=${lang}`,
      });

      dispatch({
        type: Types.GET_USER_CART_ID,
        payload: userCartResponse.data.cart,
      });
      dispatch({
        type: Types.GET_FEATURE_PRODUCT,
        payload: featuredProductsResponse.data.tireSizes.map((tireSize) => {
          return {
            ...tireSize,
            tire: [
              {
                ...tireSize.tire[0],
                certifiedBy:
                  tireSize.tire[0].certifiedBy.length > 0
                    ? tireSize.tire[0].certifiedBy
                    : null,
              },
            ],
          };
        }),
      });

      dispatch({
        type: Types.GET_CART_DETAIL,
        itemIncart:
          cartInfoResponse.data.cart?.[0]?.cartItem.map((item) => {
            return {
              ...item,
              specialPrice:
                item.tireSize.specialPrice.length === 0
                  ? null
                  : item.tireSize.specialPrice,
              tireSize: {
                ...item.tireSize,
                voucherPromotion: item.tireSize.voucherPromotion
                  ? item.tireSize.voucherPromotion
                  : [],
                tire: [
                  {
                    ...item.tireSize.tire[0],
                    certifiedBy:
                      item.tireSize.tire[0].certifiedBy.length === 0
                        ? null
                        : item.tireSize.tire[0].certifiedBy,
                  },
                ],
              },
            };
          }) ?? [],
      });
    } catch (error) {
      sentryCustomCaptureException(error);
    }
  };

export const GET_SHIPPING_ADDRESS = (id, lang) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}latestshippingaddress/?userid=${id}&lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.GET_SHIPPING_ADDRESS,
        payload: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.GET_SHIPPING_ADDRESS_ERROR,
      error,
    });
  }
};

export const SET_SHIPPING_ADDRESS = (id) => async (dispatch) => {
  dispatch({
    type: Types.SET_SHIPPING_ADDRESS,
    shipAddressId: id,
  });
};

export const CREATE_SHIPPING_ADDRESS = (query) => async (dispatch) => {
  try {
    if (query === "reset") {
      dispatch({
        type: Types.SHIPPING_ADDRESS,
        shipAddressId: undefined,
      });
    } else {
      const {
        address,
        city,
        country,
        deliverTo,
        phoneNumber,
        state,
        userId,
        zipcode,
      } = query;
      let response = await Axios.axios.post(
        `${API_URL}createshippingaddress/?address=${address}&city=${city}&country=${country}&deliverto=${deliverTo}&phonenumber=${phoneNumber}&state=${state}&userid=${userId}&zipcode=${zipcode}`
      );
      // const response = await Axios.axios({
      //       method: 'post',
      //       url: `${API_URL}createshippingaddress`,
      //       data: query

      //   })
      if (response) {
        dispatch({
          type: Types.SHIPPING_ADDRESS,
          shipAddressId: response.data.id,
        });
      }
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.SHIPPING_ADDRESS_ERROR,
      error,
    });
  }
};

export const UPDATE_CART_ITEM_DISABLE = (id, value) => (dispatch) => {
  dispatch({
    type: Types.UPDATE_CART_ITEM_DISABLE,
    payload: { id, value },
  });
};

export const CREATE_CART_ITEM =
  (itemId, itemQuantity, itemPrice, cartId, _cartItems, lang, specialPriceId) =>
  async (dispatch) => {
    // let cartItems = _cartItems;
    let cartItems =
      (await Axios.axios
        .get(`${API_URL}api/v1/cart`)
        .then((res) => res.data.cart?.[0]?.cartItem)) ?? [];

    try {
      if (window._paq) {
        window._paq.push(["clearEcommerceCart"]);
      }

      let updateCartTotalPrice = 0;

      cartItems.forEach((item) => {
        const price = item.specialPrice
          ? item.specialPrice.specialPrice
          : item.tireSize.price;
        updateCartTotalPrice = updateCartTotalPrice += item.quantity * price;
      });

      cartItems = [
        ...cartItems.map((item) => {
          return {
            id: parseInt(item.id),
            quantity: item.quantity,
            specialPriceId: item.specialPrice?.id
              ? parseInt(item.specialPrice?.id)
              : null,
            tireSize: parseInt(item.tireSize.id),
          };
        }),
      ];

      if (specialPriceId) {
        cartItems.push({
          tireSize: itemId,
          quantity: itemQuantity,
          specialPrice: specialPriceId,
        });
      } else {
        cartItems.push({
          tireSize: itemId,
          quantity: itemQuantity,
        });
      }

      updateCartTotalPrice = updateCartTotalPrice + itemQuantity * itemPrice;

      await Axios.axios.put(`${API_URL}api/v1/cart/${cartId}`, {
        totalAmount: updateCartTotalPrice,
        cartItem: cartItems,
      });

      const getCartDetailResponse = await Axios.axios.get(
        `${API_URL}api/v1/cart`
      );
      const getCartDetailResponseData =
        getCartDetailResponse.data.cart?.[0]?.cartItem.map((item) => {
          return {
            ...item,
            specialPrice:
              item.tireSize.specialPrice.length === 0
                ? null
                : item.tireSize.specialPrice,
            tireSize: {
              ...item.tireSize,
              voucherPromotion: item.tireSize.voucherPromotion
                ? item.tireSize.voucherPromotion
                : [],
              tire: [
                {
                  ...item.tireSize.tire[0],
                  certifiedBy:
                    item.tireSize.tire[0].certifiedBy.length === 0
                      ? null
                      : item.tireSize.tire[0].certifiedBy,
                },
              ],
            },
          };
        }) ?? [];

      for (let i = 0; i < getCartDetailResponseData.length; i++) {
        const tireSize = getCartDetailResponseData[i].tireSize;
        const price =
          getCartDetailResponseData[i].specialPrice &&
          getCartDetailResponseData[i].specialPrice.length > 0
            ? getCartDetailResponseData[i].specialPrice[0].specialPrice
            : tireSize.price;

        const productName = `${tireSize.tire[0].brand.brandName} ${
          tireSize.tire[0].title
        }${
          tireSize.tire[0].productCode
            ? ` [${tireSize.tire[0].productCode}]`
            : ""
        }`;

        if (window._paq) {
          window._paq.push([
            "addEcommerceItem",
            tireSize.tire[0].productCode
              ? tireSize.tire[0].productCode
              : tireSize.id,
            productName,
            "Tire",
            price,
            getCartDetailResponseData[i].quantity,
          ]);
        }
      }

      if (window._paq) {
        window._paq.push(["trackEcommerceCartUpdate", updateCartTotalPrice]);
      }

      dispatch({
        type: Types.CREATE_CART_ITEM,
        payload: getCartDetailResponseData,
      });
    } catch (error) {
      sentryCustomCaptureException(error);
    }
  };

export const DEACTIVE_PROMO = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      query
    );
    dispatch({
      type: Types.DEACTIVE_PROMO,
      payload: response.data.data,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
  }
};

export const EXPORT_ORDER = (orderId) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${IMPORT_URL}orderExport?orderId=${orderId}`
    );
    dispatch({
      type: Types.EXPORT_ORDER,
      exportDetails: response.data,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.EXPORT_ORDER,
      exportDetails: error.message,
    });
  }
};

export const UPDATE_PRICE = (itemId, selectPrice) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_PRICE,
    payload: { itemId, selectPrice },
  });
};
export const RECENT_UPDATE_PRICE =
  (itemId, selectPrice) => async (dispatch) => {
    dispatch({
      type: Types.RECENT_UPDATE_PRICE,
      payload: { itemId, selectPrice },
    });
  };

export const NORTIFYME = (userId, productId) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}notifyme/?tiresizeId=${productId}&userId=${userId}`
    );
    if (response.data.status) {
      dispatch({
        type: Types.NORTIFY_ME,
        payload: new Date().getTime(),
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.NORTIFY_ME_ERROR,
      error,
    });
  }
};

export const CLEAR_DATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_DATA,
    searchData: undefined,
  });
};
export const GET_STOCK_LIST = (itemIds) => async (dispatch) => {
  let finalresponse = await Axios.axios({
    method: "patch",
    url: `${IMPORT_URL}stock?ids=${itemIds}`,
  });
  if (finalresponse) {
    dispatch({
      type: Types.GET_STOCK_LIST,
      stockList: finalresponse.data.tireSizes.map((n) => {
        return {
          node: n,
        };
      }),
    });
  }
};

export const NEW_CONFIRM_ORDER_2 = (
  payload,
  userId,
  invoiceTotal,
  createOrder,
  promotionValidate
) => {
  return async (dispatch) => {
    try {
      const currentDate = moment();
      const estimatedDeliveryDate =
        createOrder.deliveredDate.format("DD-MM-YYYY HH:mm");
      let isReservedStock = false;

      const orderItem = payload.map((item) => {
        const itemSpecialPrice = getSpecialPrice(item.tireSize, userId);
        const hasSpecialPrice =
          itemSpecialPrice &&
          itemSpecialPrice.length > 0 &&
          ((item.quantity >= itemSpecialPrice[0].minPurchaseQuantity &&
            itemSpecialPrice[0].specialPrice < item.tireSize.price) ||
            itemSpecialPrice[0].minPurchaseQuantity === 1);

        const itemPrice = hasSpecialPrice
          ? itemSpecialPrice[0].specialPrice
          : item.tireSize.price;

        const totalPrice = item.quantity * itemPrice;
        const discountAmount = 0.0;

        let orderItemPayload = {
          tireSize: item.tireSize.id,
          additionalQuantity: 0,
          discountAmount,
          quantity: item.quantity,
          price: itemPrice,
          totalPrice,
          totalPriceAfterDiscount: totalPrice - item.quantity * discountAmount,
          specialPrice: hasSpecialPrice ? itemSpecialPrice[0].id : null,
        };

        if ((item.reservedStockQuantity ?? 0) > 0) {
          isReservedStock = true;
          orderItemPayload = {
            ...orderItemPayload,
            reservedStock: {
              quantity: item.reservedStockQuantity,
              totalQuantity: item.reservedStockQuantity,
            },
          };
        }

        return orderItemPayload;
      });

      let requestBody = {
        orderDate: currentDate.format("DD-MM-YYYY HH:mm"),
        estimatedDelivery: estimatedDeliveryDate,
        totalQuantity: createOrder.totalQuantity,
        isCancelled: false,
        isReservedStock,
        orderStatus: {
          status: "Placed",
          statusDateTime: currentDate.format("DD-MM-YYYY HH:mm:ss"),
        },
        orderItem,
        invoice: {
          discountAmount: invoiceTotal.discount,
          invoiceType: "Billable",
          isOpenInvoice: true,
          paymentType: "Credit Check",
          shippingCharge: invoiceTotal.shippingCharge,
          taxAmount: invoiceTotal.totalTax,
          totalExcludeTax: invoiceTotal.totalAmount,
          totalIncludeTax: invoiceTotal.totalAmount + invoiceTotal.totalTax,
        },
      };
      if (promotionValidate?.voucher && promotionValidate?.promotionId) {
        requestBody = {
          ...requestBody,
          voucher: promotionValidate.voucher,
          voucherPromotion: promotionValidate.promotionId,
        };
      }

      const createOrderReponse = await Axios.axios.post(
        `${API_URL}api/v1/orders`,
        requestBody
      );
      if (createOrderReponse.data) {
        if (window._paq) {
          window._paq.push([
            "trackEcommerceOrder",
            createOrderReponse.data.id,
            invoiceTotal.totalAmount +
              invoiceTotal.totalTax +
              invoiceTotal.shippingCharge -
              invoiceTotal.discount,
            invoiceTotal.totalAmount,
            invoiceTotal.totalTax,
            invoiceTotal.shippingCharge,
            invoiceTotal.discount,
          ]);
        }

        dispatch({
          type: Types.CREATE_ORDER,
          orderDetails: createOrderReponse.data,
        });
      }
    } catch (error) {
      sentryCustomCaptureException(error);
      dispatch({
        type: Types.CONFIRM_ORDER_ERROR,
        error,
      });
    }
  };
};

export const NEW_CONFIRM_CALLOFF =
  (payload, userId, createOrder) => async (dispatch) => {
    try {
      const currentDate = moment();

      const requestBody = {
        callOffDate: currentDate.format("DD-MM-YYYY HH:mm"),
        totalQuantity: createOrder.totalQuantity,
        orderStatus: {
          status: "Placed",
          statusDateTime: currentDate.format("YYYY-MM-DD HH:mm:ss"),
        },
        callOffItem: payload.map((item) => {
          return {
            quantity: item.quantity,
            price: 0,
            orderNumber: item.orderNumber,
            productCode: item.tireSize.tire[0].productCode,
            orderItem: item.id,
          };
        }),
        shippingAddress: {
          id: createOrder.shippingAddressId,
        },
      };

      const createCallOffResponse = await Axios.axios.post(
        `${API_URL}api/v1/call-offs`,
        requestBody
      );
      if (createCallOffResponse.data) {
        dispatch({
          type: Types.CONFIRM_CALLOFF,
          orderDetails: createCallOffResponse.data,
        });
      }
    } catch (error) {
      sentryCustomCaptureException(error);
      dispatch({
        type: Types.CONFIRM_CALLOFF_ERROR,
        error,
      });
    }
  };

export const EXPORT_CALLOFF = (orderId) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${IMPORT_URL}callOffExport?callOffId=${orderId}`
    );
    dispatch({
      type: Types.EXPORT_CALLOFF,
      calloffExportDetails: response.data,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.EXPORT_CALLOFF,
      calloffExportDetails: error.message,
    });
  }
};

export const SEND_MAIL_CALLOFF = (payload) => async (dispatch) => {
  try {
    if (payload === "reset") {
      dispatch({
        type: Types.SEND_MAIL_CALLOFF,
        payload: undefined,
      });
    } else {
      const {
        email,
        calloffId,
        errorMessage,
        phoneNumber,
        defaultCommunication,
      } = payload;
      const response = await Axios.axios.get(
        `${API_URL}notifyCallOffViaEmail/?email=${email}&callOffId=${calloffId}&phone=${phoneNumber}&defaultCommunication=${defaultCommunication}&errorMessage=${errorMessage}`
      );
      dispatch({
        type: Types.SEND_MAIL_CALLOFF,
        payload: response.data.status,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.SEND_MAIL_CALLOFF_ERROR,
      error,
    });
  }
};
export const VERIYFY_VOUCHER = (payload) => async (dispatch) => {
  const { items, userId, voucher, lang } = payload;
  let finalresponse;
  // let final_itmes = JSON.stringify(items)
  await Axios.axios({
    method: "post",
    url: `${API_URL}verifyVoucher/`,
    data: JSON.stringify({
      items: items,
      userId: userId,
      voucher: voucher,
      lang: lang,
    }),
  }).then(function (response) {
    finalresponse = response.data;
  });

  if (finalresponse) {
    dispatch({
      type: Types.VERIYFY_VOUCHER,
      verifyVoucher: finalresponse,
    });
  }
};
export const GET_PROMO_VOUCHER = (lang, userId) => async (dispatch) => {
  try {
    const response = await Axios.axios.get(
      `${API_URL}getVoucherPromotion/?lang=${lang}&userId=${userId}`
    );
    if (response) {
      dispatch({
        type: Types.GET_PROMO_VOUCHER,
        promoVoucher: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.GET_PROMO_VOUCHER_ERROR,
      error,
    });
  }
};

export const VALIDATE_USER_FOR_FIRSTTIME_PROMO =
  (payload) => async (dispatch) => {
    try {
      const { userId, voucher } = payload;
      const response = await Axios.axios.get(
        `${API_URL}validateUserForVoucher/?userId=${userId}&voucher=${voucher}`
      );
      if (response) {
        dispatch({
          type: Types.VALIDATE_USER_FOR_FIRSTTIME_PROMO,
          userValidated: response.data,
        });
      }
    } catch (error) {
      sentryCustomCaptureException(error);
      dispatch({
        type: Types.VALIDATE_USER_FOR_FIRSTTIME_PROMO_ERROR,
        error,
      });
    }
  };
