import * as Types from "../../constents/actionTypes";
import Axios from "../../models/Axios";
// import {API_URL, API_REQUESTS} from '../../constents/helpers'
import { v4 as uuidv4 } from "uuid";
import { API_REQUESTS, API_URL, PARENTS_ID } from "../../constents/helpers";
import {
  GET_PRODUCT_FEATURE_ITEM_QUERY,
  GET_PRODUCT_FILTER_QUERY,
} from "../queries/product";
import { sentryCustomCaptureException } from "../../utils/custom-capture-exception";

export const GET_FEATURE_PRODUCT =
  (lang, currentDate, currentTime) => async (dispatch) => {
    try {
      const response = await Axios.axios.post(
        `${API_URL}api/v1/tire-sizes/featured?showasFeaturedProduct=1&validFrom=${currentDate} ${currentTime}&_operatorValidFrom=<=&validUntil=${currentDate} ${currentTime}&_operatorValidUntil=>=&lang=${lang}`
      );
      dispatch({
        type: Types.GET_FEATURE_PRODUCT,
        payload: response.data.data.tireSizes.map((tireSize) => {
          return {
            ...tireSize,
            tire: [
              {
                ...tireSize.tire[0],
                certifiedBy:
                  tireSize.tire[0].certifiedBy.length > 0
                    ? tireSize.tire[0].certifiedBy
                    : null,
              },
            ],
          };
        }),
      });
    } catch (error) {
      sentryCustomCaptureException(error);
      dispatch({ type: Types.GET_FEATURE_PRODUCT_ERROR, error: error });
    }
  };

export const GET_PRODUCT_DETAILS = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_DETAIL,
      productDetail: response.data.data.getTireSize,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};

export const GET_PRODUCT_IMAGE = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_IMAGE,
      productImage: response.data.data.getTireSize.mediaFiles,
      productLine: response.data.data.getTireSize.productLine,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_IMAGE_ERROR, error: error });
  }
};
export const GET_PRODUCT_FEATURES = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_FEATURE,
      productFeature: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_TECH = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_TECH,
      productTechnology: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_CATEGORY = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_CATEGORY,
      productCategory: response.data.data.getTireSize.tire[0].tireCategory,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_EULABELS = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_EULABELS,
      productEulabels: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_PRESS = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_PRODUCT_PRESS,
      productpress: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};
export const GET_PRODUCT_DETAIL_ALL_DATA =
  (productId, lang) => async (dispatch) => {
    try {
      const response = await Axios.axios({
        method: "get",
        url: `${API_URL}api/v1/tire-size/${productId}?lang=${lang}`,
      });

      const payload = {
        productDetail: response.data.tireSize,
        productImage: response.data.tireSize.mediaFiles.map((mediaFile) => {
          return {
            element: mediaFile,
          };
        }),
        productLine: response.data.tireSize.productLine,
        tireDetails: {
          ...response.data.tireSize.tire[0],
          factbook:
            response.data.tireSize.tire[0].factbook.length > 0
              ? response.data.tireSize.tire[0].factbook
              : null,
          certifiedBy:
            response.data.tireSize.tire[0].certifiedBy.length > 0
              ? response.data.tireSize.tire[0].certifiedBy
              : null,
        },
        productTechnology: {
          technologyLabel: response.data.tireSize.tire[0].technologyLabel,
          technology:
            response.data.tireSize.tire[0].technology.length > 0
              ? response.data.tireSize.tire[0].technology.map((tech) => {
                  return {
                    element: {
                      ...tech,
                      image: {
                        image: {
                          fullpath: tech?.image?.fullpath ?? null,
                        },
                      },
                    },
                  };
                })
              : null,
        },
        productFeature: {
          featureandBenefitLabel:
            response.data.tireSize.tire[0].featureandBenefitLabel,
          featureandBenefit:
            response.data.tireSize.tire[0].featureandBenefit.length > 0
              ? response.data.tireSize.tire[0].featureandBenefit.map(
                  (featureandBenefit) => {
                    return {
                      element: {
                        ...featureandBenefit,
                        image: {
                          image: {
                            fullpath:
                              featureandBenefit?.image?.fullpath ?? null,
                          },
                        },
                      },
                    };
                  }
                )
              : null,
        },
        productEulabels: {
          euTireLabelLabel: response.data.tireSize.tire[0].euTireLabelLabel,
          euTireLabel:
            response.data.tireSize.tire[0].euTireLabel.length > 0
              ? response.data.tireSize.tire[0].euTireLabel.map((eulabels) => {
                  return {
                    element: {
                      ...eulabels,
                      image: {
                        image: {
                          fullpath: eulabels?.image?.fullpath ?? null,
                        },
                      },
                    },
                  };
                })
              : null,
        },
        productpress: {
          euTireLabelLabel: response.data.tireSize.tire[0].euTireLabelLabel,
          euTireLabel:
            response.data.tireSize.tire[0].euTireLabel.length > 0
              ? response.data.tireSize.tire[0].euTireLabel.map((eulabels) => {
                  return {
                    element: {
                      ...eulabels,
                      image: {
                        image: {
                          fullpath: eulabels?.image?.fullpath ?? null,
                        },
                      },
                    },
                  };
                })
              : null,
        },
        productCategory: response.data.tireSize.tire[0].tireCategory,
      };

      dispatch({
        type: Types.GET_PRODUCT_DETAIL_ALL_DATA,
        ...payload,
      });
    } catch (error) {
      sentryCustomCaptureException(error);
      dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
    }
  };
export const GET_TIRE_DETAIL = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    dispatch({
      type: Types.GET_TIRE_DETAIL,
      tireDetails: response.data.data.getTireSize.tire[0],
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PRODUCT_DETAIL_ERROR, error: error });
  }
};

export const GET_PROMO_LIST = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.PRODUCTS}`,
      data: { query: query },
    });
    if (response) {
      dispatch({
        type: Types.GET_PROMO_LIST,
        promoList: response.data.data.getPromotionListing.edges,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_PROMO_LIST_ERROR, error: error });
  }
};

export const CLEARDATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEARDATA,
  });
};

export const UPDATE_FEATURE_PRODUCT_CART_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_FEATURE_PRODUCT_CART_QUANTITY,
      payload: { itemId, quantity },
    });
  };

// export const SAVE_FEEDBACK = (userId, feedbackData) => async (dispatch) => {
//     const saveFeedbackKey = uuidv4();
//     const saveFeedbackQuery = `mutation {
//         createUserFeedback(
//           parentId: 169386,
//           key: "${saveFeedbackKey}",
//           input: {
//             user: {id: ${userId}, type: "object"},
//             feedback: "${feedbackData}"
//           }) {
//           success
//           message
//           output(defaultLanguage: "en") {
//             id
//           }
//         }
//       }
//       `
//     const saveFeedbackResponse = await Axios.axios.post(`${API_URL}${API_REQUESTS.USER}`, { query: saveFeedbackQuery });
//
//     dispatch({
//         type: Types.SAVE_FEEDBACK,
//         payload: saveFeedbackResponse.data.data.createUserFeedback.output.id
//     })
// }

export const SAVE_FEEDBACK = (userId, feedbackData) => async (dispatch) => {
  let saveFeedbackResponse;
  for (let i = 0; i < feedbackData.length; i++) {
    const saveFeedbackKey = uuidv4();
    const saveFeedbackQuery = `mutation {
            createUserFeedbackAnswer(
              parentId: ${PARENTS_ID.CREATE_FEEDBACK_ANSWER},
              key: "${saveFeedbackKey}", 
              input: {
                feedbackAnswer: "${feedbackData[i].ans}",
                user: {id: ${userId}, type: "object"},
                question: {id: ${feedbackData[i].id}, type: "object"}
              } 
            ){
                success
                message
                output(defaultLanguage: "en") {
                  id
                  
                }
                }
          }
          `;
    saveFeedbackResponse = await Axios.axios.post(
      `${API_URL}${API_REQUESTS.USER}`,
      { query: saveFeedbackQuery }
    );
  }

  dispatch({
    type: Types.SAVE_FEEDBACK,
    payload: saveFeedbackResponse.data.data.createUserFeedbackAnswer.output.id,
  });
};
export const GET_FEEFBACK_QUESTION = (payload) => async (dispatch) => {
  try {
    const { userId, lang } = payload;
    const response = await Axios.axios.get(
      `${API_URL}feedbackquestion/?userId=${userId}&lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.GET_FEEFBACK_QUESTION,
        feedbackQuestion: response.data.feedbackQuestions,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_FEEFBACK_QUESTION_ERROR, error: error });
  }
};
export function CLEAR_PRODUCT_DATA() {
  return (dispatch) => {
    dispatch({
      type: Types.CLEAR_PRODUCT_DATA,
    });
  };
}

export const GET_FILTER_PRODUCT = () => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/tire-sizes/match-codes`,
    });
    if (response) {
      dispatch({
        type: Types.GET_FILTER_PRODUCT,
        filterProductItem: response.data.matchCodes.map((matchCode) => {
          return {
            node: { matchCode },
          };
        }),
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.GET_FILTER_PRODUCT_ERROR,
      error,
    });
  }
};
