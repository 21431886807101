import * as Types from "../../constents/actionTypes";
import _ from "lodash";
import moment from "moment";

const defaultState = {
  userNotification: [],
  userStock: [],
  profileDetails: { id: null, trackingConsent: undefined },
  cheqDetails: [],
  openInvoices: [],
  targetInfo: {},
  creditDetails: {},
  userOrders: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.GET_USER_DETAILS: {
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          ...action.profileDetails,
        },
      };
    }
    case Types.DOES_USER_CONSENT_TRACKING: {
      return {
        ...state,
        profileDetails: {
          ...state.profileDetails,
          trackingConsent: action.trackingConsent,
        },
      };
    }
    case Types.GET_USER_CREDIT: {
      return {
        ...state,
        creditDetails: action.creditDetails,
      };
    }
    case Types.GET_USER_DEFERRED_CHEQ: {
      let Invoicelist = action.openInvoices;

      let todayDate = moment()
        .subtract(6, "months")
      //check if date is valid | BASIS is if date is future date it is valid
      const isDateValid = (dateToCheck) => {
        return moment.unix(dateToCheck) > todayDate;
      };

      //first filter | Based on date
      const dateFilteredData = Invoicelist.filter((item) => {
        return isDateValid(item.paymentDateTime);
      });
      const finalList = dateFilteredData.sort((a, b) => {
        // Sorting
        return new Date(b.paymentDateTime) - new Date(a.paymentDateTime);
        // Sorting ends
      });

      return {
        ...state,
        openInvoices: finalList,
        cheqDetails: action.cheqDetails.sort((a, b) => {
          // Sorting
          return new Date(a.dueDate) - new Date(b.dueDate);
          // Sorting ends
        }),
      };
    }
    case Types.GET_USER_NOTIFICATION_SETTING: {
      return {
        ...state,
        userNotificationSettings: action.userNotificationSettings,
      };
    }
    case Types.GET_USER_NOTIFICATION: {
      return {
        ...state,
        userNotification: action.userNotification,
        offset: 0,
      };
    }
    case Types.LOADMORE_NOTIFICATION: {
      const finalList = state.userNotification.concat(
        action.moreUserNotification
      );
      return {
        ...state,
        userNotification: finalList,
        loadMore: action.loadMore,
        offset: action.offset,
      };
    }
    case Types.GET_USER_STOCK: {
      let reservedStockList = [];
      let stockList = [];
      let stockListFinal = [];
      let defaultList = action.userStock;

      for (let i = 0; i < defaultList.length; i++) {
        if (
          defaultList[i] &&
          defaultList[i].isReservedStock &&
          (defaultList[i].isCancelled === null ||
            defaultList[i].isCancelled === false)
        ) {
          stockListFinal.push(defaultList[i]);
        }
      }

      let todayDate = moment()
        .subtract(12, "months")
      //check if date is valid | BASIS is if date is Not lessdate it is valid
      const isDateValid = (dateToCheck) => {
        return moment.unix(dateToCheck) > todayDate;
      };

      //first filter | Based on date
      const dateFilteredData = stockListFinal.filter((item) => {
        return isDateValid(item.orderDate);
      });
      const finalList = dateFilteredData.sort((a, b) => {
        // Sorting
        return moment.unix(b.orderDate) - moment.unix(a.orderDate);
        // Sorting ends
      });

      for (let i = 0; i < finalList.length; i++) {
        if (finalList[i] && finalList[i].isReservedStock) {
          let orderitems = finalList[i].orderItem;
          let orderList = finalList[i];
          for (let k = 0; k < orderList.orderItem.length; k++) {
            if (orderList.orderItem[k].reservedStock) {
              stockList.push(orderList);
            }
          }
          for (let j = 0; j < orderitems.length; j++) {
            if (orderitems[j].reservedStock) {
              reservedStockList.push(orderitems[j]);
            }
          }
        }
      }

      return {
        ...state,
        stockList: stockList,
        userStock: reservedStockList,
      };
    }
    case Types.GET_USER_COMPLAINT: {
      return {
        ...state,
        userComplaintList: action.userComplaintList,
      };
    }
    case Types.CANCEL_USER_COMPLAINT: {
      return {
        ...state,
        cancelComplaint: action.cancelComplaint,
      };
    }
    case Types.GET_USER_ORDERS: {
      return {
        ...state,
        userOrders: action.userOrders,
      };
    }
    case Types.GET_USER_CALLOFFORDER: {
      return {
        ...state,
        userCalloff: action.userCalloff,
      };
    }
    case Types.GET_CALLOFF_DETAILS: {
      return {
        ...state,
        calloffDetail: action.calloffDetail,
      };
    }
    case Types.SAVE_PROFILE: {
      return {
        ...state,
        saveDetails: action.saveDetails,
      };
    }
    case Types.VERIFY_PASSWORD: {
      return {
        ...state,
        passwordverify: action.passwordverify,
      };
    }
    case Types.VERIFY_PASSWORD_ERROR: {
      return {
        ...state,
        verifyfail: action.verifyfail,
      };
    }
    case Types.CHANGE_PASSWORD: {
      return {
        ...state,
        updatedpassword: action.updatedpassword,
      };
    }
    case Types.CREATE_USER_COMPLAINT: {
      return {
        ...state,
        userComplaint: action.userComplaint,
      };
    }
    case Types.GET_USER_ADDRESS: {
      return {
        ...state,
        userAddress: action.userAddress,
      };
    }
    case Types.GET_TIRELIST: {
      return {
        ...state,
        tireDetails: action.tireDetails,
      };
    }
    case Types.GET_NOTIFICATION_LIST: {
      return {
        ...state,
        notificationList: action.notificationList,
      };
    }
    case Types.UPDATE_USER_NOTIFICATION_SETTING: {
      return {
        ...state,
        updateNotificationSettings: action.updateNotificationSettings,
      };
    }
    case Types.UPDATE_USER_NOTIFICATION: {
      return {
        ...state,
        updateUserNotification: action.updateUserNotification,
      };
    }
    case Types.CREATE_USER_NOTIFICATION_SETTING: {
      return {
        ...state,
        createdNotificationSettings: action.createdNotificationSettings,
      };
    }
    case Types.CANCEL_ORDER: {
      return {
        ...state,
        cancelOrder: action.cancelOrder,
      };
    }
    case Types.GET_ORDER_DETAILS: {
      return {
        ...state,
        orderDetail: action.orderDetail,
      };
    }
    case Types.CLEAR_ORDER_DATA: {
      return {
        ...state,
        orderDetail: null,
        calloffDetail: null,
      };
    }
    case Types.FILTER_ORDER: {
      return {
        ...state,
        orderFilter: action.orderFilter,
      };
    }
    case Types.UPDATE_RESERVED_STOCK_ADD_TO_CART_QUANTITY: {
      const updateAddToCartQtyListProductState = _.cloneDeep(state.userStock);
      const updateAddToCartQtyItemIndex =
        updateAddToCartQtyListProductState.findIndex(
          (item) => item.reservedStock.id === action.payload.itemId
        );
      let updateAddToCartQtyItem =
        updateAddToCartQtyListProductState[updateAddToCartQtyItemIndex];
      updateAddToCartQtyItem.addToCartQty = action.payload.quantity;
      updateAddToCartQtyListProductState[updateAddToCartQtyItemIndex] =
        updateAddToCartQtyItem;

      return {
        ...state,
        userStock: _.cloneDeep(updateAddToCartQtyListProductState),
      };
    }

    case Types.REMOVE_RESERVED_STOCK_QUANTITY: {
      const updateListProductState = _.cloneDeep(state.userStock);
      const updateAddToCartQtyItemIndex = updateListProductState.findIndex(
        (item) => item.id === action.itemId
      );
      let updateAddToCartQtyItem =
        updateListProductState[updateAddToCartQtyItemIndex];
      updateAddToCartQtyItem.addToCartQty = 0;
      updateListProductState[updateAddToCartQtyItemIndex] =
        updateAddToCartQtyItem;

      return {
        ...state,
        userStock: _.cloneDeep(updateListProductState),
      };
    }

    case Types.SHOW_SIMILER: {
      const updateShowSimilerState = _.cloneDeep(state.userStock);
      const updateAddToCartQtyItemIndex = updateShowSimilerState.findIndex(
        (item) => item.reservedStock.id === action.payload.itemId
      );
      let updateshowHideItem =
        updateShowSimilerState[updateAddToCartQtyItemIndex];
      updateshowHideItem.showhide = action.payload.showHide;
      updateShowSimilerState[updateAddToCartQtyItemIndex] = updateshowHideItem;
      return {
        ...state,
        userStock: _.cloneDeep(updateShowSimilerState),
      };
    }
    case Types.CLEAR_DATA: {
      return {
        ...state,
        passwordverify: undefined,
      };
    }
    case Types.CLEAR_USER_DATA: {
      return {
        ...state,
        cheqDetails: [],
        openInvoices: [],
        profileDetails: { id: null },
        userNotification: [],
        userStock: [],
      };
    }
    case Types.UPDATE_USER_PHONENUMBER: {
      return {
        ...state,
        updatedphone: action.updatedphone,
      };
    }
    case Types.UPDATE_USER_PHONENUMBER_ERROR: {
      return {
        ...state,
        updatedphoneError: action.serverError,
      };
    }
    case Types.UPDATE_USER_EMAIL: {
      return {
        ...state,
        userEmail: action.userEmail,
      };
    }
    case Types.UPDATE_USER_EMAIL_ERROR: {
      return {
        ...state,
        userEmailError: action.serverError,
      };
    }
    case Types.GET_TARGET_INFO: {
      return {
        ...state,
        targetInfo: action.targetInfo,
      };
    }
    default:
      return state;
  }
};
