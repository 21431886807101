import { API_URL } from "../constents/helpers";
import * as Sentry from "@sentry/react";
import Axios from "./Axios";
import { sentryCustomCaptureException } from "../utils/custom-capture-exception";

// const loginInterface = {
//     'username': 'username',
//     'password' : 'password'
// }

// const getLoginParams = ({username,password}) => {
//     return {
//         [loginInterface['username']]: username,
//         [loginInterface['password']] : password
//     }
// }

const Storage = window.localStorage;
const SessionStorage = window.sessionStorage;
class Auth {
  static _data;
  static user_id; //We can have userId from cognitoTokens.
  static isUserAuthenticated;

  static _clear() {
    this._data = undefined;
    this.user_id = undefined;
    this.isUserAuthenticated = undefined;
    this.username = undefined;
  }

  static tryGetUserDataFromLocalStorage() {
    const user_data = Storage.getItem("data");

    try {
      return JSON.parse(Buffer.from(user_data, "base64").toString("utf-8"));
    } catch (e) {
      return undefined;
    }
  }

  static initialize() {
    console.log("initializing user authentication state");

    const auth_data = localStorage.getItem("auth_data");
    if (!auth_data) {
      console.log(
        "Auth data not found in local storage. User will be redirected to login page."
      );
      return undefined;
    }

    const user_data = Storage.getItem("data");
    if (!user_data) {
      console.log(
        "User data not found in local storage. User will be redirected to login page."
      );
      return undefined;
    }

    const userData = this.tryGetUserDataFromLocalStorage();
    if (!userData)
      sentryCustomCaptureException(
        new Error(
          "Error while parsing user data from local storage. User will be redirected to login page."
        )
      );

    try {
      // try to parse auth data in case it's not in the correct format
      const _authData = JSON.parse(
        Buffer.from(auth_data, "base64").toString("utf-8")
      );
    } catch (e) {
      if (userData && userData.userData) {
        Sentry.setUser({
          id: userData.userData.id,
          email: userData.userData.email,
          username: userData.userData.username,
        });
      } else {
        Sentry.setUser({
          id: userData.user_id,
        });
      }
      sentryCustomCaptureException(
        new Error(
          "Error while parsing auth data from local storage. User will be redirected to login page."
        )
      );

      return undefined;
    }

    this.isUserAuthenticated = userData["isUserAuthenticated"];
    this.user_id = userData["user_id"];
    this._data = userData;

    if (userData && userData.userData) {
      Sentry.setUser({
        id: userData.userData.id,
        email: userData.userData.email,
        username: userData.userData.username,
      });
    } else {
      Sentry.setUser({
        id: userData.user_id,
      });
    }

    return {
      isUserAuthenticated: this.isUserAuthenticated,
      userId: this.user_id,
      userData: this._data["userData"],
    };
  }
  // static _setDataFromJWTToken(token) {
  //     const payload = JSON.parse(atob(token.split('.')[1]))
  //     // this.userId = payload['custom:userId']
  //     this.username = payload['cognito:username']
  //     this.isUserAuthenticated = true
  //     this.userPoolId = payload['iss'].split('/')[3]
  // }

  static async loginWithUserNameAndPassword(args) {
    const sentryPayload = {
      username: args.username,
      data: Buffer.from(args.password).toString("base64"),
      lang: args.lang,
    };

    try {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const LOGIN_URL = `${API_URL}api/v1/login`;

      const { data } = await Axios.unauthenticatedAxios.post(
        LOGIN_URL,
        {
          username: args.username,
          password: args.password,
          lang: args.lang,
        },
        axiosConfig
      );

      return this.afterLogin(data);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          requestPayload: sentryPayload,
          message: "Something went wrong while logging in",
        });
        scope.setLevel("error");
        sentryCustomCaptureException(e);
      });

      const response = e.response;
      if (response) {
        const data = response.data;
        if (data) {
          const message = data.details ?? data.message ?? data.error;

          throw new Error(message);
        } else {
          throw new Error("Server Error");
        }
      } else {
        throw new Error("Server Error");
      }
    }
  }

  static logout() {
    this._clear();
    Storage.removeItem("data");
    Storage.removeItem("auth_data");
    SessionStorage.removeItem("cart");
    SessionStorage.removeItem("cartId");
  }

  static get data() {
    // if (this._data) {
    //   Sentry.setUser({
    //     id: this._data.userData.id,
    //     email: this._data.userData.email,
    //     username: this._data.userData.username,
    //   });
    // }
    return this._data;
  }

  //setTokens after LogIn
  static async afterLogin(data) {
    const userData = await Axios.unauthenticatedAxios.get(
      `${API_URL}api/v1/user`,
      {
        headers: {
          Authorization: `Bearer ${data["accessToken"]}`,
        },
      }
    );

    const username = userData.data.username;
    const user_id = userData.data.id;
    const isUserAuthenticated = true;

    this.user_id = user_id;
    this.username = username;
    this.isUserAuthenticated = isUserAuthenticated;

    const authData = {
      username,
      user_id,
      isUserAuthenticated,
      userData: {
        email: userData.data.email,
        username: userData.data.username,
        id: userData.data.id,
      },
    };

    // Storage.setItem("auth_data", btoa(JSON.stringify(data)));
    // Storage.setItem("data", btoa(JSON.stringify(authData)));
    Storage.setItem(
      "data",
      Buffer.from(JSON.stringify(authData)).toString("base64")
    );
    Storage.setItem(
      "auth_data",
      Buffer.from(JSON.stringify(data)).toString("base64")
    );

    // initialize Auth object
    Auth.initialize();

    return {
      user_id,
      isUserAuthenticated,
      userData: {
        email: userData.data.email,
        username: userData.data.username,
        id: userData.data.id,
      },
    };
  }
}

export default Auth;
