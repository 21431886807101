export const GET_USER_STOCK_QUERY = (userId, lang) => {
  return `{
        getUser(id: ${userId}, defaultLanguage: "${lang}") {
          order {
            ... on object_order {
              id
              orderNumber
              orderDate
              totalQuantity
              estimatedDelivery
              deliveryCertificate
              returnCertificate  
              isCancelled
              isReservedStock
              orderStatus {
                ... on object_orderStatus {
                  id
                  status
                  statusDateTime
                }
              }
              orderItem {
                ... on object_orderItem {
                  id
                  tireSize {
                    ... on object_tireSize {
                      id                
                      width
                      height
                      diameter
                      contructionType
                      loadRating
                      loadRating1
                      speedRating
                      speedRating1
                      speedRating2
                      suffix
                      loadCapacity
                      oeKey
                      dotCode
                      code
                      stock
                      tire {
                          id                  
                          title
                          sizeDescription
                          frFlag
                          ssrMark
                        brand {
                          ... on object_brand {
                            brandName
                          }
                        }
                        segment {
                          ... on object_segment {
                            name
                          }
                        }
                        certifiedBy {
                          ... on object_certifiedBy {
                            tooltip
                            icon {
                              fullpath
                            }
                          }
                        }
                      }
                    }
                  }
                  reservedStock {
                    ... on object_reservedStock {
                      id
                      quantity
                    }
                  }
                }
              }
              
            }
          }
        }
      }`;
};

export const GET_USER_RECENTLY_VIEW_QUERY = (userId, lang) => {
  return `{
      getUser(id: ${userId}, defaultLanguage: "${lang}") {
        recentView {
        ... on object_recentView {
          tireSize {
            ... on object_tireSize {
              id
              width
              height
              diameter
              contructionType
              loadRating
              loadRating1
              speedRating
              speedRating1
              speedRating2
              suffix
              loadCapacity
              oeKey
              price
              stock
              outofStockMessage
              dotCode
              validFrom
              validUntil
              specialPrice {
                ... on object_specialPrice {
                  specialPrice
                  id
                  validFrom
                  validTo
                  minPurchaseQuantity
                  user{
                    ... on object_user{
                      id
                    }
                  }
                }
              }
              tire {
                ... on object_tire {
                  id
                  title
                  isForSale
                  sizeDescription
                  frFlag
                  ssrMark
                  segment {
                    ... on object_segment {
                      name
                    }
                  }
                  brand {
                    ... on object_brand {
                      brandName
                    }
                  }
                  certifiedBy {
                       ... on object_certifiedBy {
                      tooltip
                      icon {
                        fullpath
                      }
                    }             
                  }
                }
              }
              voucherPromotion {
                ... on object_voucherPromotion {
                   id
                  voucherCode
                  startDate
                  endDate
                  minPurchaseAmount
                  provideFreeGift
                  freeGiftQuantity
              discountType
                  discountTypeValue
                  title
                  description                      
                }
              }
            }
          }
        }
      }
    }
  }`;
};

export const GET_USER_TARGET_INFO_QUERY = (userId) => {
  return `{
      getUser(id: ${userId}) {
        id
        targetSales
        amountOrdered
        percentageAccomplished
        fromDate
        toDate
        familyName
      }
    }`;
};
