import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
// import { Col, Row, Input, Button } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../Layout/Loader";
// import { Redirect } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../store/actions/layout";
import { onCollapseToggle } from "../../store/actions/layout";
import AutoCompleted from "./AutoCompleted";
const mapStateToProps = (state) => {
  return {
    searchedProductsListat: state.Cart.searchedProductsListat,
    defaultLanguage: state.Layout.defaultLanguage,
    closeSearchBox: state.Layout.closeSearchBox,
    collapsefilter: state.Layout.collapsefilter,
    userId: state.Auth.userId,
    filterProductItem: state.Product.filterProductItem,
    listProducts: state.Cart.listProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    DEFAULT_LANGUAGE: (payload) => dispatch(DEFAULT_LANGUAGE(payload)),
    // onPageChange: (payload) => dispatch(onPageChange(payload)),
    onCollapseToggle: (payload) => dispatch(onCollapseToggle(payload)),
  };
};

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: "",
      searchField: true,
      isLoading: false,
    };
  }

  // UNSAFE_componentWillReceiveProps(nextprops) {
  //   const { searchedProductsListat } = this.props;

  //   if (searchedProductsListat !== nextprops.searchedProductsListat) {
  //     this.setState({
  //       isLoading: false,
  //     });
  //     if ((this.state.searchFilter ?? "").length > 0) {
  //       nextprops.matomoInstance.trackSiteSearch({
  //         keyword: this.state.searchFilter,
  //         count: nextprops.listProducts.length,
  //       });
  //       nextprops.matomoInstance.trackEvent({
  //         category: "products",
  //         action: "search-event-with-no-result",
  //         name: this.state.searchFilter,
  //       });
  //       this.setState({ searchFilter: "" });
  //     }
  //   }

  //   // if(window.location.pathname !== "/products"){
  //   //     this.setState({
  //   //         searchField: false
  //   //     })
  //   // }
  //   // if(nextprops.collapsefilter === true){
  //   //     this.setState({
  //   //         searchField: false
  //   //     })
  //   // }
  //   // if(nextprops.closeSearchBox === false){
  //   //     this.setState({
  //   //         searchField: false
  //   //     })
  //   // }
  // }

  onSearch = () => {
    const { userId } = this.props;
    if (!this.state.searchFilter) {
      toast.error(this.props.t("tostMsg.headerSearch"));
      return;
    }
    const filters = {
      searchFilter: this.state.searchFilter,
      lang: this.props.defaultLanguage,
      userId: userId,
    };
    this.setState({ isLoading: true });

    const searchParam = new URLSearchParams(this.props.location.search);
    searchParam.set("searchData", this.state.searchFilter);
    this.props.history.replace({
      pathname: "/products",
      search: searchParam.toString(),
    });
  };

  onSelectSearch = (value) => {
    if (!value) {
      toast.error(this.props.t("tostMsg.headerSearch"));
      return;
    }
    const searchParam = new URLSearchParams(this.props.location.search);
    searchParam.set("searchData", value);
    this.props.history.replace({
      pathname: "/products",
      search: searchParam.toString(),
    });
  };

  handalKeypress = (e) => {
    if (e.key === "Enter") {
      this.onSearch();
    }
  };

  // onChange = (e) => {
  //     const { name, value } = e.target
  //     this.setState({
  //         [name]: value
  //     })
  // }
  onChange = (name, value) => {
    // const { name, value } = e.target
    this.setState({
      [name]: value,
    });
  };
  mobileSearch = () => {
    /* added to collapse the filter layout */
    if (window.location.pathname === "/products") {
      if (this.props.collapsefilter)
        this.props.onCollapseToggle({ collapsefilter: false });
    }

    /* ================================== */

    // this.setState({
    //     searchField: !this.state.searchField
    // })
  };

  langChange = (e) => {
    const { value } = e.target;
    this.props.changeLanguage(value);
    this.props.DEFAULT_LANGUAGE(value);
  };

  closeAlert = () => {
    let alertBox = document.getElementsByClassName("alert");
    if (alertBox[0].classList.contains("show"))
      alertBox[0].style.display = "none";
    //if (alertBox[0].classList.contains("active")) { alertBox[0].classList.remove('active'); }
  };

  render() {
    const { isMobile, defaultLanguage, filterProductItem } = this.props;
    return (
      <Fragment>
        {/* {isMobile && this.state.searchField ? <div className="dropdownLayer" onClick={this.mobileSearch}></div> : null} */}
        {/* {this.state.redirect && <Redirect push to="/products" />} */}
        {isMobile && window.innerWidth < 700 ? (
          <Col xs={2} sm={2} md={2} lg={2} xl={2}>
            <div style={{ position: "relative", height: "34px" }}>
              {window.location.pathname === "/products" &&
                this.state.searchField && (
                  <div className="mobileSearch">
                    {/* <Input type="text" className="rounded-pill" placeholder={t('topHeader.productSearch')} name="searchFilter" onChange={this.onChange} onKeyPress={this.handalKeypress} onClick={this.closeAlert} autoFocus/><Button color="primary" className="search-btn rounded-pill" onClick={this.onSearch}><span className="icon-search"></span></Button> */}
                    <AutoCompleted
                      onSearch={this.onSearch}
                      filterProductItem={filterProductItem}
                      setValue={this.onChange}
                      onSelectSearch={this.onSelectSearch}
                      isMobile={isMobile}
                    />
                  </div>
                )}
              {/* <Button color="primary" className="search-btn rounded-pill search-btn-mob" onClick={this.mobileSearch}><span className="icon-search"></span></Button> */}
            </div>
          </Col>
        ) : (
          <div className="top-search-box">
            <AutoCompleted
              onSearch={this.onSearch}
              filterProductItem={filterProductItem}
              setValue={this.onChange}
              onSelectSearch={this.onSelectSearch}
              isMobile={isMobile}
            />
          </div>
        )}

        {/* <ToastContainer limit={1}/> */}
      </Fragment>
    );
  }
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))
);
