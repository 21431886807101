import React, {Component, Fragment} from 'react';
import { withTranslation } from 'react-i18next';
import logout from '../../assets/logout.png'
import { Link } from 'react-router-dom';
class UserNav extends Component {
    constructor(props){
        super(props)
        this.state={
            
        }

    }

   

    render() {
        const {t, showUserNav, isMobile} = this.props
        return (
            <Fragment>
                
                <div className={this.props.collapseUserNav?"left-box open": "left-box"}>
                    <div className="usernav">
                        <h3>{t('topHeader.account')} </h3>

                        <ul>
                            <li onClick={isMobile?showUserNav:null} className={window.location.pathname === "/profile"? "active":null}>
                                <Link to="/profile"><span className="icon-user"></span>{t('profilePage.title')}</Link>
                            </li>
                            <li onClick={isMobile?showUserNav:null} className={window.location.pathname === "/security"? "active":null}>
                                <Link to="/security"><span className="icon-security"></span>{t('securityPage.title')}</Link>
                            </li>
                            <li onClick={isMobile?showUserNav:null} className={window.location.pathname === "/accounting"? "active":null}>
                                <Link to="/accounting"><span className="icon-accounting-card"></span> {t('accountPage.title')}</Link>
                            </li>
                            <li onClick={isMobile?showUserNav:null} className={window.location.pathname === "/notificationsetting"? "active":null}>
                                <Link to="/notificationsetting"><span className="icon-notification"></span> {t('notificationSettingTitle')}</Link>
                            </li>
                            {/* <li onClick={isMobile?showUserNav:null} className={window.location.pathname === "/reservedstock"? "active":null}>
                                <Link to="/reservedstock"><span className="icon-reserved-stock"></span> {t('reserveStockTitle')}</Link>
                            </li> */}
                            <li onClick={isMobile?showUserNav:null} className={window.location.pathname === "/complaints"? "active":null}>
                                <Link to="/complaints"><span className="icon-complaints"></span> {t('complaintPage.title')}</Link>
                            </li>
                            <li>
                                <Link to="#!" onClick={this.props.isLogout}><span><img alt="logout" src={logout} style={{width:'1.5rem'}}></img></span> {t('topHeader.logout')}</Link>
                            </li>

                        </ul>

                    </div>
                    
                </div>
            </Fragment>
        );
    }
}


export default withTranslation()(UserNav);