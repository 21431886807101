import * as Types from "../../constents/actionTypes";
const Storage = window.localStorage;

const defaultState = {
  collapsefilter: false,
  collapseUserNav: true,
  defaultLanguage: Storage.i18nextLng ? Storage.i18nextLng : "en",
  languageDirection: "ltr",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.FILTER_TOGGLE: {
      return {
        ...state,
        collapsefilter: action.payload,
      };
    }
    case Types.USERNAV_TOGGLE: {
      return {
        ...state,
        collapseUserNav: action.payload,
      };
    }
    case Types.DEFAUL_LANGUAGE: {
      return {
        ...state,
        defaultLanguage: action.payload,
      };
    }
    case Types.PAGE_CHANGE: {
      return {
        ...state,
        closeSearchBox: action.payload,
      };
    }
    case Types.GET_ADMIN_DATA: {
      return {
        ...state,
        adminData: action.adminData,
      };
    }
    case Types.SET_LANGUAGE_DIRECTION: {
      return {
        ...state,
        languageDirection: action.payload,
      };
    }
    case Types.CLEAR_LAYOUT_DATA: {
      return {
        ...state,
        collapseUserNav: true,
        collapsefilter: false,
      };
    }
    default:
      return state;
  }
};
