import React, { Fragment, lazy, Suspense } from "react";
// import PageTitle from './PageTitle';
// import Filters from './filters';
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Header from "../Header";
// import ListProduct from '../../Components/ListPrducts';
// import FeatureProduct from '../../Components/FeatureProduct';
import { connect } from "react-redux";
// import ProductDetails from '../../Components/ProductDetails';
import { isMobileOnly as isMobile, isTablet } from "react-device-detect";
import {
  Redirect,
  Route as ReactRouteRoute,
  withRouter,
  Switch,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Auth from "../../models/Auth";
import {
  CLEAR_AUTH_DATA,
  LOGOUT,
  SET_IS_USER_AUTHENTICATED,
  UPDATE_USER_CONSENT_TRACKING,
} from "../../store/actions/auth";
import { CLEAR_DATA } from "../../store/actions/cart";
import {
  CLEAR_LAYOUT_DATA,
  DEFAULT_LANGUAGE,
  GET_ADMIN_DATA,
  onCollapseUserNav,
  setLanguageDirection,
} from "../../store/actions/layout";
import {
  CLEAR_PRODUCT_DATA,
  GET_FILTER_PRODUCT,
} from "../../store/actions/products";
import {
  CLEAR_USER_DATA,
  GET_USER_CALLOFFORDER,
  GET_USER_DETAILS,
  GET_USER_ORDERS,
} from "../../store/actions/userDetail";
import Loader from "../Loader";
import Axios from "../../models/Axios";
import { API_REQUESTS, API_URL } from "../../constents/helpers";
import { withTranslation } from "react-i18next";
import Onboarding from "../../Components/Onboarding";
import ResetPasswords from "../../Components/ResetPasswords";
import Register from "../../Components/Register";
import RegisterSuccess from "../../Components/Register/success";
import { GET_USER_INFO_QUERY } from "../../store/queries/user";
import * as Sentry from "@sentry/react";
import isEqual from "lodash/isEqual";
import DownloadPage from "../../Components/DownloadPage";

// import CallModal from '../../Components/CallModal/CallModal';
const Storage = window.localStorage;
const PageNotFound = lazy(() => import("../../Components/PageNotFound"));
const Promotions = lazy(() => import("../../Components/Promotions"));
const Cart = lazy(() => import("../../Components/Cart"));
const Products = lazy(() => import("../../Components/Products/product"));
const Home = lazy(() => import("../../Components/Home/index"));
const UserDetails = lazy(() => import("../../Components/UserDetails"));
const About = lazy(() => import("../../Components/About"));
const CallModal = lazy(() => import("../../Components/CallModal/CallModal"));
const Newproducts = lazy(() => import("../../Components/Products/product"));
const ReservedStock = lazy(() => import("../../Components/ReservedStock"));
const GiveFeedbackModal = lazy(() =>
  import("../../Components/GiveFeedbackModal/GiveFeedbackModal")
);

const Orders = lazy(() => import("../../Components/Orders"));
const BrandLanding = lazy(() => import("../../Components/BrandLanding"));
const Dashboard = lazy(() => import("../../Components/Dashboard"));

const SidebarSummary = lazy(() => import("../../Components/SidebarSummary"));

const Route = Sentry.withSentryRouting(ReactRouteRoute);

const mapStateToProps = (state) => {
  return {
    collapsefilter: state.Layout.collapsefilter,
    collapseUserNav: state.Layout.collapseUserNav,
    isUserAuthenticated: state.Auth.isUserAuthenticated,
    userId: state.Auth.userId,
    userData: state.Auth.userData,
    userEmail: state.UserDetail.profileDetails?.email,
    userUsername: state.UserDetail.profileDetails?.username,
    userCustomerNumber: state.UserDetail.profileDetails?.userNumber,
    trackingConsent: state.UserDetail.profileDetails?.trackingConsent,
    closeSearchBox: state.Layout.closeSearchBox,
    lang: state.Layout.defaultLanguage,
    mailsend: state.Cart.mailsend,
  };
};

const mapDispatchToProps = {
  SET_IS_USER_AUTHENTICATED: () => SET_IS_USER_AUTHENTICATED(),
  LOGOUT: () => LOGOUT(),
  onCollapseUserNav: (payload) => onCollapseUserNav(payload),
  DEFAULT_LANGUAGE: (payload) => DEFAULT_LANGUAGE(payload),
  GET_ADMIN_DATA: (payload) => GET_ADMIN_DATA(payload),
  setLanguageDirection: (payload) => setLanguageDirection(payload),
  GET_USER_ORDERS: () => GET_USER_ORDERS(),
  GET_USER_CALLOFFORDER: () => GET_USER_CALLOFFORDER(),
  CLEAR_DATA: () => CLEAR_DATA(),
  CLEAR_AUTH_DATA: () => CLEAR_AUTH_DATA(),
  CLEAR_LAYOUT_DATA: () => CLEAR_LAYOUT_DATA(),
  CLEAR_USER_DATA: () => CLEAR_USER_DATA(),
  CLEAR_PRODUCT_DATA: () => CLEAR_PRODUCT_DATA(),
  GET_FILTER_PRODUCT: () => GET_FILTER_PRODUCT(),
  GET_USER_DETAILS: () => GET_USER_DETAILS(),
  // DOES_USER_CONSENT_TRACKING: (payload) => DOES_USER_CONSENT_TRACKING(payload),
  UPDATE_USER_CONSENT_TRACKING: (payload) =>
    UPDATE_USER_CONSENT_TRACKING(payload),
  // FILTER_PRODUCT: (payload) => FILTER_PRODUCT(payload),
};
let pathname = window.location.pathname;
class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isMobile: false,
      productId: null,
      isLoading: false,
      redirect: false,
      callModalToggle: false,
      giveFeedbackModalToggle: false,
      acceptConsentCheckbox: false,
      showLogoutModal: false,
    };
    this.trackPageView = this.trackPageView.bind(this);
  }

  trackPageView() {
    if (!this.props.matomoInstance) return;
    if (this.props.userCustomerNumber) {
      this.props.matomoInstance.pushInstruction(
        "setUserId",
        this.props.userCustomerNumber.toString()
      );
    }
    this.props.matomoInstance.trackPageView();
  }

  componentDidMount() {
    Auth.initialize();

    this.resize();
    window.addEventListener("resize", this.resize);
    this.props.DEFAULT_LANGUAGE(this.props.lang);
    this.setLanguageDirectionHandler(this.props.lang);

    window.addEventListener("app:apiCallReturns401", () => {
      this.setState({
        showLogoutModal: true,
      });
      this.isLogout(false);
    });
    window.addEventListener("app:invalidAuthData", () => {
      this.setState({
        showLogoutModal: true,
      });
      this.isLogout(false);
    });

    const loader = document.querySelector(".loader-in-shell");
    if (loader) {
      loader.remove();
    }

    // this.trackPageView();
    this.props.history.listen(this.trackPageView);

    if (!this.props.isUserAuthenticated) {
      if (
        pathname === "/home" ||
        // pathname.startsWith("/auth/onboarding/") ||
        pathname === "/products" ||
        pathname === "/cart" ||
        pathname === "/promotions" ||
        pathname === "/notification" ||
        pathname === "/profile" ||
        pathname === "/orders" ||
        pathname === "/security" ||
        pathname === "/accounting" ||
        pathname === "/notificationsetting" ||
        pathname === "/reservedstock" ||
        pathname === "/complaints" ||
        pathname === "/newproducts" ||
        pathname === "/reservedstock-2" ||
        pathname === "/dashboard"
      ) {
        if (!Auth.data) {
          this.props.LOGOUT();
          console.log(
            "Redirected to login page since there is no proper auth data"
          );
          this.moveToLogin();
        } else {
          this.props.SET_IS_USER_AUTHENTICATED();
        }
      } else if (
        pathname === "about" ||
        pathname === "/download" ||
        pathname === "/brand" ||
        pathname === "/sales" ||
        pathname === "/tiretips" ||
        pathname === "/history" ||
        pathname === "/rnd" ||
        pathname === "/morethentire" ||
        pathname === "/testwinner" ||
        pathname === "/madeingermany" ||
        pathname === "/no-1-in-OE" ||
        pathname === "/safety" ||
        pathname === "/tiremaintenance" ||
        pathname === "/tiretechnology"
      ) {
        // do nothing
      } else if (
        pathname.startsWith("/auth/onboarding") ||
        pathname.startsWith("/register") ||
        pathname.startsWith("/register/success") ||
        pathname.startsWith("/auth/reset-password")
      ) {
      } else {
        if (!Auth.data) {
          this.props.LOGOUT();
          console.log(
            "Redirected to login page since there is no proper auth data"
          );
          this.moveToLogin();
        } else {
          this.props.SET_IS_USER_AUTHENTICATED();
        }
      }
    }
    this.props.GET_ADMIN_DATA(this.props.lang);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      this.setLanguageDirectionHandler(this.props.lang);
      this.props.GET_ADMIN_DATA(this.props.lang);
    }

    if (this.props.isUserAuthenticated && this.props.userId) {
      this.props.GET_USER_DETAILS();
    }

    if (prevProps.userData !== this.props.userData && this.props.userData) {
      Sentry.setUser({
        id: this.props.userData.id,
        email: this.props.userData.email,
        username: this.props.userData.username,
      });
    }

    if (
      prevProps.userCustomerNumber !== this.props.userCustomerNumber &&
      this.props.userCustomerNumber
    ) {
      this.props.matomoInstance.pushInstruction(
        "setUserId",
        this.props.userCustomerNumber.toString()
      );
      this.trackPageView();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      window.innerWidth >= 768 &&
      window.innerWidth <= 960 &&
      this.state.isMobile !== isTablet
    ) {
      this.resize();
    }
  }

  resize = () => {
    if (window.innerWidth >= 768 && window.innerWidth <= 960) {
      this.setState({
        isMobile: true,
      });
    }
    if (this.state.isMobile !== isMobile) {
      this.setState({
        isMobile: isMobile,
      });
    }
  };

  moveToLogin = () => {
    this.props.history.push("/home");
  };

  isLogout = (redirect = true) => {
    this.props.LOGOUT();

    // remove userId from matomo
    this.props.matomoInstance.pushInstruction("resetUserId");
    // remove userId from sentry
    Sentry.setUser(null);

    const payload = {
      collapseUserNav: false,
    };
    // let removefromFilters = {}
    this.props.onCollapseUserNav(payload);
    this.props.CLEAR_DATA();
    this.props.CLEAR_AUTH_DATA();
    this.props.CLEAR_LAYOUT_DATA();
    this.props.CLEAR_PRODUCT_DATA();
    this.props.CLEAR_USER_DATA();
    // this.props.FILTER_PRODUCT(removefromFilters)

    if (redirect) this.props.history.push("/home");

    Storage.removeItem("data");
    Storage.removeItem("rememberData");
  };

  callModalCloseHandler = () => {
    this.setState({
      callModalToggle: !this.state.callModalToggle,
    });
  };
  giveFeedbackModalClose = () => {
    this.setState({
      giveFeedbackModalToggle: !this.state.giveFeedbackModalToggle,
    });
  };

  setLanguageDirectionHandler = (lang) => {
    let languageDirection = "";
    if (lang === "he") {
      languageDirection = "rtl";
    }
    if (lang === "en") {
      languageDirection = "ltr";
    }
    this.props.setLanguageDirection(languageDirection);
  };

  updateUserTrackingConsent = () => {
    this.props.UPDATE_USER_CONSENT_TRACKING(this.props.userId);
  };

  render() {
    const {
      collapsefilter,
      isUserAuthenticated,
      collapseUserNav,
      changeLanguage,
    } = this.props;
    return (
      <Fragment>
        {this.state.isLoading && <Loader />}
        <Header
          isMobile={this.state.isMobile}
          isLogout={this.isLogout}
          userId={this.props.userId}
          changeLanguage={(lang) => {
            changeLanguage(lang);
          }}
          callModalCloseHandler={this.callModalCloseHandler}
          giveFeedbackModalClose={this.giveFeedbackModalClose}
        />
        <Suspense fallback={<Loader />}>
          <Container
            className={
              !isUserAuthenticated
                ? "loginpage"
                : collapsefilter ||
                  (collapseUserNav &&
                    (window.location.pathname === "/profile" ||
                      window.location.pathname === "/security" ||
                      window.location.pathname === "/accounting" ||
                      window.location.pathname === "/notificationsetting" ||
                      window.location.pathname === "/complaints"))
                ? "filter-on"
                : ""
            }
          >
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route
                exact
                path="/"
                render={() => {
                  return isUserAuthenticated ? (
                    <Redirect to="/dashboard" />
                  ) : (
                    <Redirect to="/home" />
                  );
                }}
              />
              <Route path="/about" component={About} />
              <Route path="/home" component={Home} />
              <Route path="/auth/onboarding/:token" component={Onboarding} />
              <Route path="/register" exact component={Register} />
              <Route path="/register/success" component={RegisterSuccess} />
              <Route
                path="/auth/reset-password/:token"
                component={ResetPasswords}
              />
              <Route path="/promotions" component={Promotions} />
              <Route
                path="/cart"
                component={() => <Cart isMobile={this.state.isMobile} />}
              />
              <Route
                path="/products"
                component={() => (
                  <div className="products-wrapper">
                    {/* <SidebarSummary /> */}
                    <div
                      className="product-page"
                      style={
                        this.props.closeSearchBox
                          ? { paddingTop: "3rem" }
                          : null
                      }
                    >
                      <Products
                        modalToggle={this.modalToggle}
                        isMobile={this.state.isMobile}
                      />{" "}
                    </div>{" "}
                  </div>
                )}
              />
              <Route
                path="/newproducts"
                component={() => (
                  <div
                    className="product-page"
                    style={
                      this.props.closeSearchBox ? { paddingTop: "3rem" } : null
                    }
                  >
                    <Newproducts
                      modalToggle={this.modalToggle}
                      isMobile={this.state.isMobile}
                    />{" "}
                  </div>
                )}
              />
              <Route
                path="/reservedstock"
                component={() => (
                  <div
                    className="product-page"
                    style={
                      this.props.closeSearchBox ? { paddingTop: "3rem" } : null
                    }
                  >
                    <ReservedStock
                      modalToggle={this.modalToggle}
                      isMobile={this.state.isMobile}
                    />{" "}
                  </div>
                )}
              />
              <Route
                path="/profile"
                component={() => <UserDetails isMobile={this.state.isMobile} />}
              />
              <Route
                path="/security"
                component={() => <UserDetails isMobile={this.state.isMobile} />}
              />
              <Route
                path="/accounting"
                component={() => <UserDetails isMobile={this.state.isMobile} />}
              />
              <Route
                path="/notificationsetting"
                component={() => <UserDetails isMobile={this.state.isMobile} />}
              />
              <Route
                path="/notification"
                component={() => <UserDetails isMobile={this.state.isMobile} />}
              />
              <Route
                path="/complaints"
                component={() => <UserDetails isMobile={this.state.isMobile} />}
              />
              <Route
                path="/orders"
                component={() => <Orders isMobile={this.state.isMobile} />}
              />
              <Route
                path="/brand"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/history"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/rnd"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/morethentire"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/sales"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/testwinner"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/madeingermany"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/no-1-in-OE"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/tiretips"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/safety"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/tiremaintenance"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/tiretechnology"
                component={() => (
                  <BrandLanding isMobile={this.state.isMobile} />
                )}
              />
              <Route
                path="/download"
                component={() => (
                  <DownloadPage isMobile={this.state.isMobile} />
                )}
              />
              <Route path="*" component={() => <PageNotFound />} />
            </Switch>
          </Container>
        </Suspense>

        <Suspense fallback={<Loader />}>
          <CallModal
            callModalToggle={this.state.callModalToggle}
            callModalClose={() => {
              this.callModalCloseHandler();
            }}
          />
        </Suspense>
        {/* <Suspense fallback={<Loader />}>
          <GiveFeedbackModal
            giveFeedbackModalToggle={this.state.giveFeedbackModalToggle}
            giveFeedbackModalClose={this.giveFeedbackModalClose}
            feedbackDataSuccess={this.props.feedbackData}
          />
        </Suspense> */}
        <Modal isOpen={this.props.trackingConsent === false}>
          <ModalHeader>{this.props.t("consentTitle")}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup check style={{ paddingRight: "1.25rem" }}>
                <Input
                  id="consent-accept-checkbox"
                  type="checkbox"
                  onChange={(e) => {
                    this.setState({
                      acceptConsentCheckbox: e.target.checked,
                    });
                  }}
                  style={{ ...(this.props.lang === "he" ? { right: 0 } : {}) }}
                />
                <Label
                  check
                  htmlFor="consent-accept-checkbox"
                  dangerouslySetInnerHTML={{
                    __html: this.props
                      .t("consentCheckbox")
                      .replace(
                        "<Link>",
                        '<a target="_blank" rel="noopener noreferer" href="/מדיניות פרטיות-סופי-22.03.23  .pdf">'
                      )
                      .replace("</Link>", "</a>"),
                  }}
                ></Label>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.acceptConsentCheckbox}
              onClick={this.updateUserTrackingConsent}
            >
              {this.props.t("consentAccept")}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showLogoutModal}>
          <ModalHeader>You've been logged out.</ModalHeader>
          <ModalBody>
            You've been logged out from the Webshop. To continue, please login
            again.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                this.setState(
                  {
                    showLogoutModal: false,
                  },
                  () => {
                    this.isLogout();
                  }
                );
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
        <ToastContainer limit={1} />
      </Fragment>
    );
  }
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))
);
