import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { register } from "./serviceWorkerRegistration";

import { BrowserRouter as Router } from "react-router-dom";
import "./scss/style.scss";

import Main from "./Layout/Main";
import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import {
  MatomoContext,
  MatomoProvider,
  createInstance,
} from "@datapunt/matomo-tracker-react";
import * as Sentry from "@sentry/react";

import "./i18n";
import i18n from "./i18n";
import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import metadata from "./metadata.json";
import CacheBuster from "react-cache-buster";
import Loader from "./Layout/Loader";
const Storage = window.localStorage;

let lang = Storage.i18nextLng;

let dir;

const changeLanguage = (lng) => {
  if (lng === "he") {
    dir = "rtl";
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
  if (lng === "en") {
    dir = "ltr";
    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    // dir = "ltr"
  }
  i18n.changeLanguage(lng);
};

if (lang === "he") {
  dir = "rtl";
}
if (lang === "en") {
  dir = "ltr";
}

if (!lang) {
  const browserLanguage = navigator.language;
  if (browserLanguage.includes("en")) {
    changeLanguage("en");
  }
  if (browserLanguage.includes("he")) {
    changeLanguage("he");
  }
}

const store = configureStore();
const history = createBrowserHistory();

const matomoInstance = createInstance({
  urlBase: "https://appx.thisisdmg.com/",
  siteId: 7,
});

const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://6d9e728bf5054a8289a23ebceffdd336@log.infra.thisisdmg.com/51",
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration(),
  ],
  release: `0.${metadata.sprintVersion}.${metadata.sprint}-${metadata.server}#${metadata.build}`,
  sendClientReports: false,
  environment:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_ENVIROMENT === "LIVE"
        ? "production"
        : process.env.REACT_APP_ENVIROMENT
      : `${process.env.REACT_APP_ENVIROMENT} (${process.env.NODE_ENV})`,
  // currently our sentry instance version does not support replay
  // ...(process.env.REACT_APP_ENVIROMENT !== "LIVE"
  //   ? {
  //       // This sets the sample rate to be 10%. You may want this to be 100% while
  //       // in development and sample at a lower rate in production
  //       replaysSessionSampleRate: 0.1,
  //       // If the entire session is not sampled, use the below sample rate to sample
  //       // sessions when an error occurs.
  //       replaysOnErrorSampleRate: 1.0,
  //     }
  //   : {}),
});

const app = (
  <CacheBuster
    currentVersion={`0.${metadata.sprint}.${metadata.sprintVersion}.${metadata.build}`}
    isEnabled={process.env.NODE_ENV === "production"} //If false, the library is disabled.
    isVerboseMode={true} //If true, the library writes verbose logs to console.
    loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
  >
    <Sentry.ErrorBoundary>
      <MatomoProvider value={matomoInstance}>
        <Router history={history}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<></>}>
                <MatomoContext.Consumer>
                  {(matomo) => (
                    <Main
                      changeLanguage={changeLanguage}
                      languageDirection={dir}
                      lang={lang}
                      matomoInstance={matomo}
                    />
                  )}
                </MatomoContext.Consumer>
              </Suspense>
            </QueryClientProvider>
          </Provider>
        </Router>
      </MatomoProvider>
    </Sentry.ErrorBoundary>
  </CacheBuster>
);

ReactDOM.render(app, document.getElementById("root"));
document.getElementsByTagName("html")[0].setAttribute("dir", dir);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register();
