let _API_URL;
let _IMPORT_URL;
let _IMAGE_URL;
let _FACTBOOK_URL;
let _PARENTS_ID;

export const IMPORTER_URL = process.env.REACT_APP_IMPORTER_URL;
export const ENV_API_URL = process.env.REACT_APP_BASEURL;
export const ENV_IMAGE_URL = process.env.REACT_APP_IMAGEURL;

export const API_REQUESTS = {
  PRODUCTS:
    "pimcore-graphql-webservices/product?apikey=5cd8427a511aad4d88ca4efa1b8d694a",
  USER: "pimcore-graphql-webservices/user?apikey=368318deb38ca1e8395456345f7cdcc2",
  ADMIN:
    "pimcore-graphql-webservices/adminsettings?apikey=3f6f8b756fab683fae6475095bd236b2",
};

export let PARENTS_ID_BETA = {
  CREATE_CART: 39,
  CREATE_CART_ITEM: 3,
  CREATE_RECENT_VIEW: 32,
  CREATE_USER_NOTIFY: 93,
  CREATE_USER_COMPLAINT: 87,
  CREATE_RESERVED_STOCK: 85,
  CREATE_ORDER_STATUS: 64,
  CREATE_SHIPPING_ADDRESS: 72,
  CREATE_USER_FEEDBACK: 169386,
  CREATE_FEEDBACK_ANSWER: 173457,
};
export let PARENTS_ID_STAGE = {
  CREATE_CART: 2,
  CREATE_CART_ITEM: 3,
  CREATE_RECENT_VIEW: 32,
  CREATE_USER_NOTIFY: 46,
  CREATE_USER_COMPLAINT: 38,
  CREATE_RESERVED_STOCK: 11,
  CREATE_ORDER_STATUS: 10,
  CREATE_SHIPPING_ADDRESS: 47,
  CREATE_USER_FEEDBACK: 41,
  CREATE_FEEDBACK_ANSWER: 42,
};
export let PARENTS_ID_LIVE = {
  CREATE_CART: 2,
  CREATE_CART_ITEM: 3,
  CREATE_RECENT_VIEW: 32,
  CREATE_USER_NOTIFY: 46,
  CREATE_USER_COMPLAINT: 38,
  CREATE_RESERVED_STOCK: 11,
  CREATE_ORDER_STATUS: 10,
  CREATE_SHIPPING_ADDRESS: 47,
  CREATE_USER_FEEDBACK: 41,
  CREATE_FEEDBACK_ANSWER: 42,
};

export let PARENTS_ID_BETA_10 = {
  CREATE_CART: 2,
  CREATE_CART_ITEM: 3,
  CREATE_RECENT_VIEW: 32,
  CREATE_USER_NOTIFY: 46,
  CREATE_USER_COMPLAINT: 38,
  CREATE_RESERVED_STOCK: 11,
  CREATE_ORDER_STATUS: 10,
  CREATE_SHIPPING_ADDRESS: 47,
  CREATE_USER_FEEDBACK: 41,
  CREATE_FEEDBACK_ANSWER: 42,
};

const reactEnv = process.env.REACT_APP_ENVIROMENT;

if (reactEnv === "DEVELOP") {
  _API_URL = `${ENV_API_URL}`;
  _IMPORT_URL = `${IMPORTER_URL}`;
  _FACTBOOK_URL = `${ENV_IMAGE_URL}`;
  _IMAGE_URL = `${ENV_IMAGE_URL}`;
  _PARENTS_ID = PARENTS_ID_STAGE;
} else if (reactEnv === "BETA") {
  _API_URL = `${ENV_API_URL}`;
  _IMPORT_URL = `${IMPORTER_URL}`;
  _FACTBOOK_URL = `${ENV_IMAGE_URL}`;
  _IMAGE_URL = `${ENV_IMAGE_URL}`;
  _PARENTS_ID = PARENTS_ID_BETA;
} else if (reactEnv === "STAGE") {
  _API_URL = `${ENV_API_URL}`;
  _IMPORT_URL = `${IMPORTER_URL}`;
  _FACTBOOK_URL = `${ENV_IMAGE_URL}`;
  _IMAGE_URL = `${ENV_IMAGE_URL}`;
  _PARENTS_ID = PARENTS_ID_STAGE;
} else if (reactEnv === "LIVE") {
  _API_URL = `${ENV_API_URL}`;
  _IMPORT_URL = `${IMPORTER_URL}`;
  _FACTBOOK_URL = `${ENV_IMAGE_URL}`;
  _IMAGE_URL = `${ENV_IMAGE_URL}`;
  _PARENTS_ID = PARENTS_ID_LIVE;
} else if (reactEnv === "BETA10") {
  _API_URL = `${ENV_API_URL}`;
  _IMPORT_URL = `${IMPORTER_URL}`;
  _FACTBOOK_URL = `${ENV_IMAGE_URL}`;
  _IMAGE_URL = `${ENV_IMAGE_URL}`;
  _PARENTS_ID = PARENTS_ID_BETA_10;
}

export const API_URL = _API_URL;
export const IMPORT_URL = _IMPORT_URL;
export const IMAGE_URL = _IMAGE_URL;
export const FACEBOOK_URL = _FACTBOOK_URL;
export let PARENTS_ID = _PARENTS_ID;

export const VALIDATE_REGEX = {
  // email: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
  email: /[a-zA-Z0-9._]*@[a-zA-Z0-9.-]*\.[a-z]*/,
  phone: /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im,
};

export const NOTIFICATION_LOAD_LIMIT = 15;
