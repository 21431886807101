import React from "react";
import { Input, Button } from "reactstrap";
import { IconSearch } from "../../Components/Icons";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GET_FILTER_PRODUCT } from "../../store/actions/products";

const mapStateToProps = (state) => {
  return {
    searchData: state.Cart.searchData,
  };
};

const mapDispatchToProps = {
  GET_FILTER_PRODUCT: () => GET_FILTER_PRODUCT(),
};
class AutoCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      text: this.props.searchData,
      finalArray: [],
      isSearchFocused: false,
    };
    this.handleClickForFocus = this.handleClickForFocus.bind(this);
  }

  handleClickForFocus(e) {
    const notebook = document.querySelector("#notebooks");

    if (notebook.contains(e.target)) {
      this.setState({
        isSearchFocused: true,
      });
    } else {
      this.setState({
        isSearchFocused: false,
      });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickForFocus);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickForFocus);
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.searchData === null) {
      this.setState({
        text: "",
      });
    }
  }
  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  onInputFocus = () => {
    const { filterProductItem } = this.props;
    if (!filterProductItem) {
      this.props.GET_FILTER_PRODUCT();
    }
  };

  onTextChange = (e) => {
    const value = e.target.value;
    let suggestions = [];
    let regex = /^[0-9]+$/;
    const { filterProductItem } = this.props;
    if (filterProductItem && value.match(regex) && value.length >= 3) {
      let filterData = filterProductItem.filter((item) => {
        return item.node.matchCode.includes(value);
      });

      let sortData = filterData.sort((a, b) => {
        if (a.node.speedRating < b.node.speedRating) {
          return -1;
        }
        if (a.node.speedRating > b.node.speedRating) {
          return 1;
        }
        return 0;
      });

      const match = sortData.map((o) => o.node.matchCode);
      const filtered = sortData.filter((item, index) => {
        let code = item.node.matchCode;
        return !match.includes(code, index + 1);
      });

      let sortData_2 = filtered.sort((a, b) => {
        if (a.node.loadRating < b.node.loadRating) {
          return -1;
        }
        if (a.node.loadRating > b.node.loadRating) {
          return 1;
        }
        return 0;
      });

      // let uniqueData = this.getUniqueListBy(sortData, "matchCode")

      suggestions = sortData_2;
      this.renderSuggestions(suggestions);
    }
    this.setState(() => ({
      text: value,
    }));
    this.props.setValue("searchFilter", value);
  };

  selectedText(value) {
    let searchValue = value.node.matchCode;

    // let forSearch = this.state.text
    this.setState(() => ({
      text: searchValue,
      finalArray: [],
    }));
    this.props.setValue("searchFilter", searchValue);
    this.props.onSelectSearch(searchValue);
  }

  renderSuggestions = (suggestions) => {
    if (suggestions.length) {
      // const grouped = this.groupBy(suggestions, (item) => item.node.loadRating);
      // let suggestionsArray = [];
      // for (const [key, value] of suggestions.entries()) {
      //   if (value && value.length) {
      //     let finalObject = Object.assign({ node: value[0].node, key: key });
      //     suggestionsArray.push(finalObject);
      //   }
      // }
      this.setState({
        finalArray: suggestions,
      });
    }
  };
  groupBy(list, keyGetter) {
    const map = new Map();
    let collection = [];
    list.forEach((item) => {
      const key = keyGetter(item);
      collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  onSearch = () => {
    this.props.onSearch();
    this.setState(() => ({
      finalArray: [],
    }));
  };
  render() {
    const { text } = this.state;
    const { t } = this.props;
    return (
      <div id="notebooks">
        <Input
          id="query"
          type="tel"
          className="rounded-pill"
          onFocus={() => {
            this.onInputFocus();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.onSearch();
            }
          }}
          onChange={this.onTextChange}
          value={text}
          placeholder={t("topHeader.productSearch")}
        />
        <Button
          color="primary"
          className="search-btn rounded-pill"
          onClick={() => {
            this.onSearch();
          }}
        >
          <IconSearch />
        </Button>
        {this.state.isSearchFocused && this.state.finalArray.length > 0 && (
          <ul className="suggestions">
            {this.state.finalArray.map((item, index) => (
              <li
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => this.selectedText(item)}
              >
                {item.node.matchCode}
              </li>
            ))}
          </ul>
        )}
        {/* <span>Suggestions: {suggestions.length}</span> */}
      </div>
    );
  }
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AutoCompleted)
);
