import moment from "moment";
import { PARENTS_ID } from "../../constents/helpers";

export const GET_CART_DETAIL_QUERY = (userCartId, lang) => {
  return `{
    getCart(id: ${userCartId}, defaultLanguage: "${lang}") {
      id 
      user {
        ... on object_user {
          id 
          userGroup {
            ... on object_userGroup {
              id 
              groupType
            }
          }
        }
      } 
      cartItem {
        ... on object_cartItem {
          id 
          quantity 
          specialPrice{ 
            ... on object_specialPrice { 
              id 
              specialPrice 
              validFrom 
              validTo 
              minPurchaseQuantity 
              user {
                ... on object_user{id}
              }
            }
          } 
          tireSize {
            ... on object_tireSize {
              id 
              width 
              height          
              diameter 
              contructionType 
              loadRating 
              loadRating1 
              speedRating 
              speedRating1 
              speedRating2 
              suffix 
              loadCapacity 
              oeKey 
              price 
              stock 
              outofStockMessage 
              validFrom 
              validUntil 
              specialPrice { 
                ... on object_specialPrice { 
                  id 
                  specialPrice 
                  validFrom 
                  validTo 
                  minPurchaseQuantity 
                  user {
                    ... on object_user{
                      id
                    }
                  } 
                  userGroup { 
                    ... on object_userGroup {
                      id groupType
                    }
                  }
                }
              }
              tire {
                ... on object_tire {
                  id 
                  title 
                  sizeDescription 
                  frFlag 
                  ssrMark 
                  segment {
                    ... on object_segment {
                      name
                    }
                  }
                  brand {
                    ... on object_brand {
                      brandName
                    }
                  }
                  certifiedBy {
                    ... on object_certifiedBy {
                      tooltip 
                      icon { 
                        fullpath
                      }
                    }
                  }
                }
              }
              voucherPromotion {
                ... on object_voucherPromotion {
                   id
                  voucherCode
                  startDate
                  endDate
                  minPurchaseAmount
                  provideFreeGift
                  freeGiftQuantity
              discountType
                  discountTypeValue
                  title
                  description                      
                }
              }
            }
          }
        }
      }
    }
  }`;
};

export const CREATE_CART_QUERY = (cartkey, userId) => {
  return `mutation {
    createCart(parentId: ${PARENTS_ID.CREATE_CART}, key: "cart${cartkey}", input: {totalAmount: 0.00, user: {id: ${userId}, type: "object"}}) {
      success
      message
      output(defaultLanguage: "en") {
        id
        key
        creationDate
        fullpath
        totalAmount
      }
    }
  }`;
};

export const GET_USER_CART_ID_AND_FEATURED_PRODUCTS_QUERY = (
  id,
  lang,
  currentDate,
  currentTime
) => {
  return `{
    getUser(id:${id}) {
        cart{
          ... on object_cart {
            id
          }
        }
      }
    getTireSizeListing(defaultLanguage: "${lang}", filter: "{\\"showasFeaturedProduct\\" : \\"1\\", \\"validFrom\\": {\\"$lte\\" :\\"${currentDate} ${currentTime}\\"}, \\"validUntil\\": {\\"$gte\\" :\\"${currentDate} ${currentTime}\\"} }") {
      edges {
        node {
          id
          width
          height
          diameter
          contructionType
          loadRating
          loadRating1
          speedRating
          speedRating1
          speedRating2
          suffix
          loadCapacity
          oeKey
          price
          stock
          outofStockMessage
          validFrom
          validUntil
          featureProductLabel
          specialPrice {
            ... on object_specialPrice {
              id
              validTo
              validFrom
              specialPrice
              minPurchaseQuantity
              user {
                  ... on object_user{
                    id
                  }
                }
              userGroup {
                ... on object_userGroup {
                  id
                  groupType
                }
              }
            }
          }
          tire {
            ... on object_tire {
              id
              title
              sizeDescription
              frFlag
              ssrMark
              segment {
                ... on object_segment {
                  name
                }
              }
              brand {
                ... on object_brand {
                  brandName
                }
              }
              certifiedBy {
                ... on object_certifiedBy {
                  tooltip
                  icon {
                    fullpath
                  }
                }
              }
            }
          }
          voucherPromotion {
            ... on object_voucherPromotion {
               id
              voucherCode
              startDate
              endDate
              minPurchaseAmount
              provideFreeGift
              freeGiftQuantity
          discountType
              discountTypeValue
              title
              description                      
            }
          }
        }
      }
    }
  }`;
};

export const GET_USER_CART_INFO_AND_FEATURED_PRODUCTS_QUERY = (
  id,
  lang,
  currentDate,
  currentTime
) => {
  return `{
    getUser(id:${id}) {
        cart{
          ... on object_cart {
            id
            cartItem {
              ... on object_cartItem {
                id 
                quantity 
                specialPrice{ 
                  ... on object_specialPrice { 
                    id 
                    specialPrice 
                    validFrom 
                    validTo 
                    minPurchaseQuantity 
                    user {
                      ... on object_user{id}
                    }
                  }
                } 
                tireSize {
                  ... on object_tireSize {
                    id 
                    width 
                    height          
                    diameter 
                    contructionType 
                    loadRating 
                    loadRating1 
                    speedRating 
                    speedRating1 
                    speedRating2 
                    suffix 
                    loadCapacity 
                    oeKey 
                    price 
                    stock 
                    outofStockMessage 
                    validFrom 
                    validUntil 
                    specialPrice { 
                      ... on object_specialPrice { 
                        id 
                        specialPrice 
                        validFrom 
                        validTo 
                        minPurchaseQuantity 
                        user {
                          ... on object_user{
                            id
                          }
                        } 
                        userGroup { 
                          ... on object_userGroup {
                            id groupType
                          }
                        }
                      }
                    }
                    tire {
                      ... on object_tire {
                        id 
                        title 
                        sizeDescription 
                        frFlag 
                        ssrMark 
                        segment {
                          ... on object_segment {
                            name
                          }
                        }
                        brand {
                          ... on object_brand {
                            brandName
                          }
                        }
                        certifiedBy {
                          ... on object_certifiedBy {
                            tooltip 
                            icon { 
                              fullpath
                            }
                          }
                        }
                      }
                    }
                    voucherPromotion {
                      ... on object_voucherPromotion {
                         id
                        voucherCode
                        startDate
                        endDate
                        minPurchaseAmount
                        provideFreeGift
                        freeGiftQuantity
                    discountType
                        discountTypeValue
                        title
                        description                      
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    getTireSizeListing(defaultLanguage: "${lang}", filter: "{\\"showasFeaturedProduct\\" : \\"1\\", \\"validFrom\\": {\\"$lte\\" :\\"${currentDate} ${currentTime}\\"}, \\"validUntil\\": {\\"$gte\\" :\\"${currentDate} ${currentTime}\\"} }") {
      edges {
        node {
          id
          width
          height
          diameter
          contructionType
          loadRating
          loadRating1
          speedRating
          speedRating1
          speedRating2
          suffix
          loadCapacity
          oeKey
          price
          stock
          outofStockMessage
          validFrom
          validUntil
          featureProductLabel
          specialPrice {
            ... on object_specialPrice {
              id
              validTo
              validFrom
              specialPrice
              minPurchaseQuantity
              user {
                  ... on object_user{
                    id
                  }
                }
              userGroup {
                ... on object_userGroup {
                  id
                  groupType
                }
              }
            }
          }
          tire {
            ... on object_tire {
              id
              title
              sizeDescription
              frFlag
              ssrMark
              segment {
                ... on object_segment {
                  name
                }
              }
              brand {
                ... on object_brand {
                  brandName
                }
              }
              certifiedBy {
                ... on object_certifiedBy {
                  tooltip
                  icon {
                    fullpath
                  }
                }
              }
            }
          }
          voucherPromotion {
            ... on object_voucherPromotion {
               id
              voucherCode
              startDate
              endDate
              minPurchaseAmount
              provideFreeGift
              freeGiftQuantity
          discountType
              discountTypeValue
              title
              description                      
            }
          }
        }
      }
    }
  }`;
};

export const CREATE_CART_ITEM_MUTATION = (
  createCartItemKey,
  itemQuantity,
  itemId,
  specialPriceId
) => {
  return `mutation {  
        createCartItem (
          parentId: ${PARENTS_ID.CREATE_CART_ITEM}, 
          key: "${createCartItemKey}", 
          input: {
            quantity: ${itemQuantity},
            tireSize: {id: ${itemId}, type: "object"},
            ${
              specialPriceId
                ? `specialPrice: {id: ${specialPriceId}, type: "object" }`
                : ``
            }
          } 
        ) {
          success
            message
            output(defaultLanguage: "en") {
              id
              key
              creationDate
              fullpath        
            }    
        }
      }`;
};

export const CREATE_ORDER_QUERY = (
  userId,
  orderNumber,
  createOrder,
  orderItems,
  invoiceStatus,
  orderStatus,
  promotionValidate,
  isReservedStock
) => {
  return `mutation {
      updateOrder(
      id: ${orderNumber}, 
      input: {
        orderDate: "${moment(createOrder.orderData).format(
          "DD-MM-YYYY HH:mm"
        )}",
        estimatedDelivery: "${moment(createOrder.deliveredDate).format(
          "DD-MM-YYYY HH:mm"
        )}",
        totalQuantity: ${createOrder.totalQuantity},
        isCancelled: false,
        isReservedStock: ${isReservedStock},
        orderStatus: {id: ${orderStatus}, type:"object"} ,
        user:{id: ${userId}, type: "object"},
        ${
          createOrder.shippingAddressId
            ? `shippingAddress: {id: ${createOrder.shippingAddressId}, type:"object"},`
            : ``
        }
        orderItem: [${orderItems}],
        invoice:{id:${invoiceStatus.id}, type:"object"},
        ${
          createOrder.validPromoId
            ? `promotionCode: {id: ${createOrder.validPromoId}, type:"object"},`
            : ``
        }
        ${
          promotionValidate && promotionValidate.promotionId
            ? `
          voucher: "${promotionValidate.voucher}",
          voucherPromotion: {id: ${promotionValidate.promotionId}, type:"object"},
        `
            : ``
        }
      }) 
    {
      success
      message
      output(defaultLanguage: "en") {
        id
        key
      }
    }
  }`;
};
