import * as Types from "../../constents/actionTypes";
import Axios from "../../models/Axios";
import {
  API_URL,
  API_REQUESTS,
  NOTIFICATION_LOAD_LIMIT,
} from "../../constents/helpers";
import { sentryCustomCaptureException } from "../../utils/custom-capture-exception";

export const GET_USER_DETAILS = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user`,
    });
    if (response) {
      dispatch({
        type: Types.GET_USER_DETAILS,
        profileDetails: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const SAVE_PROFILE = (query) => async (dispatch) => {
  try {
    const { id, lang, ...payload } = query;

    let response = await Axios.axios.patch(
      `${API_URL}api/v1/user/${id}?lang=${lang}`,
      payload
    );
    if (response) {
      dispatch({
        type: Types.SAVE_PROFILE,
        saveDetails: response.data.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.SAVE_PROFILE_ERROR, error });
  }
};

export const CHANGE_PASSWORD = (query) => async (dispatch) => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.set("userId", `${query.userId}`);
    bodyFormData.set("newpassword", `${query.newpassword}`);
    bodyFormData.set("confirm", `${query.confirm}`);
    bodyFormData.set("lang", `${query.lang}`);
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const CHANGEPASSWORD_URL = `${API_URL}changepassword/`;

    let response = await Axios.axios.post(
      CHANGEPASSWORD_URL,
      bodyFormData,
      axiosConfig
    );
    if (response) {
      dispatch({
        type: Types.CHANGE_PASSWORD,
        updatedpassword: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.CHANGE_PASSWORD_ERROR, error });
  }
};

export const VERIFY_PASSWORD = (query) => async (dispatch) => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.set("userId", `${query.userId}`);
    bodyFormData.set("password", `${query.password}`);
    bodyFormData.set("lang", `${query.lang}`);
    let axiosConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const VERIFY_URL = `${API_URL}verifyuser/`;

    let response = await Axios.axios.post(
      VERIFY_URL,
      bodyFormData,
      axiosConfig
    );
    if (response) {
      dispatch({
        type: Types.VERIFY_PASSWORD,
        passwordverify: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.VERIFY_PASSWORD_ERROR,
      verifyfail: error.response.data,
    });
  }
};

export const CLEAR_DATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_DATA,
  });
};

export const GET_USER_CREDIT = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user`,
    });
    dispatch({
      type: Types.GET_USER_CREDIT,
      creditDetails: response.data,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const GET_USER_DEFERRED_CHEQ = () => async (dispatch) => {
  try {
    const userDeferredCheque = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user/deferred-cheques`,
    });
    const userAccountReceivable = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user/account-receivables`,
    });

    dispatch({
      type: Types.GET_USER_DEFERRED_CHEQ,
      cheqDetails: userDeferredCheque.data.userDeferredCheques,
      openInvoices: userAccountReceivable.data.userAccountReceivables,
      // userAccountReceivable:response.data.data.getUser.userAccountReceivable
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const GET_NOTIFICATION_LIST = () => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/notification-types`,
    });
    dispatch({
      type: Types.GET_NOTIFICATION_LIST,
      notificationList: response.data.notificationTypes.map((noti) => {
        return {
          node: noti,
        };
      }),
      // userNotificationSettings : response.data.data.getUser.userNotify
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};
export const GET_USER_NOTIFICATION_SETTING = () => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user/notification-settings`,
    });
    dispatch({
      type: Types.GET_USER_NOTIFICATION_SETTING,
      userNotificationSettings: response.data.userNotificationSettings,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const UPDATE_USER_NOTIFICATION_SETTING = (item) => async (dispatch) => {
  try {
    await Axios.axios({
      method: "put",
      url: `${API_URL}api/v1/user-notify/${item.id}`,
      data: {
        viaEmail: item.viaEmail,
        viaWebsite: item.viaWebsite,
      },
    });
    dispatch({
      type: Types.UPDATE_USER_NOTIFICATION_SETTING,
      updateNotificationSettings: new Date().getTime(),
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.UPDATE_USER_NOTIFICATION_SETTING_ERROR,
      error: error,
    });
  }
};

export const CREATE_USER_NOTIFICATION_SETTING = (item) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}api/v1/user-notify`,
      data: {
        notificationType: item.notificationType.id,
        viaEmail: item.viaEmail,
        viaWebsite: item.viaWebsite,
      },
    });
    if (response) {
      dispatch({
        type: Types.CREATE_USER_NOTIFICATION_SETTING,
        createdNotificationSettings: new Date().getTime(),
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.CREATE_USER_NOTIFICATION_SETTING_ERROR,
      error: error,
    });
  }
};

export const GET_USER_NOTIFICATION = (payload) => async (dispatch) => {
  try {
    const {
      userId,
      order,
      lang,
      orderkey,
      offset,
      limit = NOTIFICATION_LOAD_LIMIT,
    } = payload;
    const response = await Axios.axios.get(
      `${API_URL}getnoticelist/?userId=${userId}&lang=${lang}&limit=${limit}&offset=${offset}&order=${order}&orderkey=${orderkey}`
    );
    // const response = await Axios.axios({
    //         method: 'post',
    //         url: `${API_URL}${API_REQUESTS}getnoticelist/?userId=${userId}&limit=3&offset=0&order=${order}&orderkey=ASC`,
    //         data: {query:query}

    // })
    dispatch({
      type: Types.GET_USER_NOTIFICATION,
      userNotification: response.data.map((noti) => {
        return {
          ...noti,
          isNew:
            typeof noti.isNew === "string" ? parseInt(noti.isNew) : noti.isNew,
        };
      }),
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const LOADMORE_NOTIFICATION = (payload) => async (dispatch) => {
  try {
    const { userId, offset, order, lang, orderkey } = payload;
    const response = await Axios.axios.get(
      `${API_URL}getnoticelist/?userId=${userId}&lang=${lang}&limit=${NOTIFICATION_LOAD_LIMIT}&offset=${offset}&order=${order}&orderkey=${orderkey}`
    );
    if (response.data.length > 0) {
      dispatch({
        type: Types.LOADMORE_NOTIFICATION,
        moreUserNotification: response.data,
        offset: offset,
        // loadMore : true
      });
    } else {
      dispatch({
        type: Types.LOADMORE_NOTIFICATION,
        moreUserNotification: response.data,
        loadMore: false,
        offset: offset,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const UPDATE_USER_NOTIFICATION = (id) => async (dispatch) => {
  try {
    await Axios.axios({
      method: "patch",
      url: `${API_URL}api/v1/notice/${id}`,
      data: { isNew: false },
    });
    dispatch({
      type: Types.UPDATE_USER_NOTIFICATION,
      updateUserNotification: new Date().getTime(),
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.UPDATE_USER_NOTIFICATION_ERROR, error: error });
  }
};

export const GET_USER_STOCK = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/orders`,
    });
    dispatch({
      type: Types.GET_USER_STOCK,
      userStock: response.data.orders,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};
export const GET_USER_COMPLAINT = () => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user/complaints`,
    });

    dispatch({
      type: Types.GET_USER_COMPLAINT,
      userComplaintList: response.data.userComplaints,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};
export const CANCEL_USER_COMPLAINT = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: query,
    });

    dispatch({
      type: Types.CANCEL_USER_COMPLAINT,
      cancelComplaint: response.data.data,
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.CANCEL_USER_COMPLAINT_ERROR, error: error });
  }
};

export const GET_USER_ORDERS = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/orders`,
    });
    dispatch({
      type: Types.GET_USER_ORDERS,
      userOrders: response.data.orders.map((order) => {
        return {
          ...order,
          orderStatus:
            order.orderStatus.length === 0 ? null : order.orderStatus,
        };
      }),
    });
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_ORDERS_ERROR, error: error });
  }
};

export const GET_USER_CALLOFFORDER = () => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/call-offs`,
    });
    if (response) {
      dispatch({
        type: Types.GET_USER_CALLOFFORDER,
        userCalloff: response.data.callOffs.map((callOff) => {
          return {
            ...callOff,
            orderStatus:
              callOff.orderStatus.length === 0 ? null : callOff.orderStatus,
          };
        }),
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_CALLOFFORDER_ERROR, error: error });
  }
};
// export const CREATE_USER_COMPLAINT = (query) => async (dispatch) => {
//     try {
//         const response = await Axios.axios({
//             method: 'post',
//             url: `${API_URL}${API_REQUESTS.USER}`,
//             data: query

//         })
//         dispatch({
//             type: Types.CREATE_USER_COMPLAINT,
//             userComplaint: response.data.data.createUserComplaint
//         })
//     } catch (error) {
//         dispatch({ type: Types.CREATE_USER_COMPLAINT_ERROR, error: error })
//     }
// }

export const CREATE_USER_COMPLAINT = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}api/v1/user/complaints`,
      data: {
        ...query,
        status: "Received",
      },
    });
    if (response) {
      dispatch({
        type: Types.CREATE_USER_COMPLAINT,
        userComplaint: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.CREATE_USER_COMPLAINT_ERROR, error: error });
  }
};

export const GET_USER_ADDRESS = () => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user`,
    });
    if (response) {
      dispatch({
        type: Types.GET_USER_ADDRESS,
        userAddress: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_USER_DETAILS_ERROR, error: error });
  }
};

export const GET_TIRELIST = (payload) => async (dispatch) => {
  try {
    const { brand, segment } = payload;
    const response = await Axios.axios.get(
      `${API_URL}productsize/?brand=${brand}&segment=${segment}`
    );
    if (response) {
      dispatch({
        type: Types.GET_TIRELIST,
        tireDetails: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_TIRELIST_ERROR, error: error });
  }
};

export const CANCEL_ORDER = (query) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "post",
      url: `${API_URL}${API_REQUESTS.USER}`,
      data: query,
    });
    if (response) {
      dispatch({
        type: Types.CANCEL_ORDER,
        cancelOrder: response.data.data.updateOrder.output.modificationDate,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.CANCEL_ORDER_ERROR, error: error });
  }
};

export const GET_ORDER_DETAILS = (query) => async (dispatch) => {
  const { id, lang } = query;
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/orders/${id}?lang=${lang}`,
    });
    if (response) {
      dispatch({
        type: Types.GET_ORDER_DETAILS,
        orderDetail: response.data.order,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_ORDER_DETAILS_ERROR, error: error });
  }
};

export const GET_CALLOFF_DETAILS = (id) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/call-offs/${id}`,
    });
    if (response) {
      dispatch({
        type: Types.GET_CALLOFF_DETAILS,
        calloffDetail: response.data.callOff,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({ type: Types.GET_CALLOFF_DETAILS_ERROR, error: error });
  }
};

export const CLEARORDERDATA = () => async (dispatch) => {
  dispatch({
    type: Types.CLEAR_ORDER_DATA,
  });
};

export const FILTER_ORDER = (payload) => async (dispatch) => {
  dispatch({
    type: Types.FILTER_ORDER,
    orderFilter: payload,
  });
};

export const UPDATE_RESERVED_STOCK_ADD_TO_CART_QUANTITY =
  (itemId, quantity) => async (dispatch) => {
    dispatch({
      type: Types.UPDATE_RESERVED_STOCK_ADD_TO_CART_QUANTITY,
      payload: { itemId, quantity },
    });
  };

export const REMOVE_RESERVED_STOCK_QUANTITY = (itemId) => async (dispatch) => {
  dispatch({
    type: Types.REMOVE_RESERVED_STOCK_QUANTITY,
    itemId: itemId,
  });
};

export const SHOW_SIMILER = (itemId, showHide) => async (dispatch) => {
  dispatch({
    type: Types.SHOW_SIMILER,
    payload: {
      itemId,
      showHide,
    },
  });
};

export const UPDATE_USER_PHONENUMBER = (payload) => async (dispatch) => {
  try {
    const { userId, lang, phoneNumber } = payload;
    const response = await Axios.axios({
      method: "patch",
      url: `${API_URL}api/v1/user/${userId}?lang=${lang}`,
      data: {
        phoneNumber,
      },
    });
    if (response) {
      dispatch({
        type: Types.UPDATE_USER_PHONENUMBER,
        updatedphone: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.UPDATE_USER_PHONENUMBER_ERROR,
      serverError: error.response.data,
    });
  }
};
export const UPDATE_USER_EMAIL = (payload) => async (dispatch) => {
  try {
    const { userId, lang, email } = payload;
    const response = await Axios.axios({
      method: "patch",
      url: `${API_URL}api/v1/user/${userId}?lang=${lang}`,
      data: {
        email,
      },
    });
    if (response) {
      dispatch({
        type: Types.UPDATE_USER_EMAIL,
        userEmail: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.UPDATE_USER_EMAIL_ERROR,
      serverError: error.response.data,
    });
  }
};

export const GET_TARGET_INFO = (payload) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "get",
      url: `${API_URL}api/v1/user`,
    });
    if (response) {
      dispatch({
        type: Types.GET_TARGET_INFO,
        targetInfo: response.data,
      });
    }
  } catch (error) {
    sentryCustomCaptureException(error);
    dispatch({
      type: Types.GET_TARGET_INFO_ERROR,
      error,
    });
  }
};
export function CLEAR_USER_DATA() {
  return (dispatch) => {
    dispatch({
      type: Types.CLEAR_USER_DATA,
    });
  };
}
