import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { Col } from "reactstrap";
// import BrandLogo from '../../assets/main-logo.svg';
import { API_URL } from "../../constents/helpers";
import ContiLogo from "../../assets/continental_logo.svg";

function Logo(props) {
  let location = useLocation();
  return (
    <Fragment>
      {/* {props.isUserAuthenticated && location.pathname === "/products"? 
                    <div className={props.collapsefilter?"filter-icon-btn active":"filter-icon-btn"} onClick={props.showfilters}><span className="icon-menu"></span></div>
                : <div className={props.collapseUserNav?"filter-icon-btn active":"filter-icon-btn"} onClick={props.showUserNav}><span className="icon-open-menu"></span></div>} */}

      {props.isUserAuthenticated &&
      // location.pathname === "/products" ||
      (location.pathname === "/newproducts" ||
        location.pathname === "/orders") ? (
        <div
          className={
            props.collapsefilter ? "filter-icon-btn active" : "filter-icon-btn"
          }
          onClick={props.showfilters}
        >
          <span className="icon-menu"></span>
        </div>
      ) : (
        <Fragment>
          {
            //   location.pathname === "/profile" ||
            location.pathname === "/security" ||
            location.pathname === "/accounting" ||
            location.pathname === "/notificationsetting" ||
            location.pathname === "/complaints" ? (
              <div
                className={
                  props.collapseUserNav
                    ? "filter-icon-btn active"
                    : "filter-icon-btn"
                }
                onClick={props.showUserNav}
              >
                <span className="icon-open-menu"></span>
              </div>
            ) : null
          }
        </Fragment>
      )}

      {props.isMobile ? (
        <Fragment>
          {props.isUserAuthenticated ? (
            <Col xs={3} sm={3} md={2} lg={4} xl={3} className="px-0 px-md-2">
              <Link to="/" className="navbar-brand">
                {/* <img src={BrandLogoMobile} alt="Kislev" /> */}
                {props.adminData && (
                  <img
                    src={
                      `${API_URL}var/assets` +
                      props.adminData.mobileLogo.fullpath
                    }
                    alt="Kislev"
                  />
                )}
              </Link>
              {/* <NavbarBrand href="/products"></NavbarBrand> */}
            </Col>
          ) : (
            <Col xs={2} sm={2} md={2} lg={4} xl={3} className="px-0 px-md-2">
              <Link to="/" className="navbar-brand">
                {/* <img src={BrandLogoMobile} alt="Kislev" /> */}
                {props.adminData && (
                  <img
                    src={
                      `${API_URL}var/assets` +
                      props.adminData.mobileLogo.fullpath
                    }
                    alt="Kislev"
                  />
                )}
              </Link>
              {/* <NavbarBrand href="/" className="d-inline-block d-lg-none mr-0">
                                <img src={BrandLogoMobile} alt="Kislev"/>
                            </NavbarBrand> */}
            </Col>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {props.isUserAuthenticated ? (
            <div className="logo-wrapper">
              <Link to="/" className="navbar-brand main-logo">
                {/* <img src={BrandLogo} alt="Kislev" /> */}
                {props.adminData && (
                  <img
                    src={
                      `${API_URL}var/assets` +
                      props.adminData.desktopLogo.fullpath
                    }
                    alt="Kislev"
                  />
                )}
              </Link>
              {/* <NavbarBrand href="/products" className="main-logo"></NavbarBrand> */}
            </div>
          ) : (
            <Col
              xs={5}
              sm={3}
              md={2}
              lg={4}
              xl={6}
              className="px-0 px-md-2 d-none d-lg-inline-block"
            >
              {(location.pathname === "/brand" ||
                location.pathname === "/sales" ||
                location.pathname === "/tiretips" ||
                location.pathname === "/history" ||
                location.pathname === "/rnd" ||
                location.pathname === "/morethentire" ||
                location.pathname === "/testwinner" ||
                location.pathname === "/madeingermany" ||
                location.pathname === "/no-1-in-OE" ||
                location.pathname === "/safety" ||
                location.pathname === "/tiremaintenance" ||
                location.pathname === "/tiretechnology") && (
                <Link to="/brand">
                  <div className="logo-conti">
                    <img src={ContiLogo} alt="conti-Logo" />
                  </div>
                </Link>
              )}
              <Link to="/" className="navbar-brand main-logo">
                {/* <img src={BrandLogo} alt="Kislev" /> */}
                {props.adminData && (
                  <img
                    src={
                      `${API_URL}var/assets` +
                      props.adminData.desktopLogo.fullpath
                    }
                    alt="Kislev"
                  />
                )}
              </Link>
              {/* <NavbarBrand href="/" className="main-logo"><img src={BrandLogo} alt="Kislev"/></NavbarBrand> */}
            </Col>
          )}
        </Fragment>
      )}

      {/* <NavbarBrand href="/" className="d-inline-block d-lg-none mr-0"><img src={BrandLogoMobile} alt="Kislev"/></NavbarBrand> */}
    </Fragment>
  );
}
export default Logo;
