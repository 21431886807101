import Layout from './layout'
import Product from './products'
import Auth from './auth'
import UserDetail from './userDetail'
import Cart from './cart'
export default {
    Layout,
    Product,
    Auth,
    UserDetail,
    Cart
}