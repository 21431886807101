import Auth from "../../models/Auth";
import * as Types from "../../constents/actionTypes";
import Axios from "../../models/Axios";
import { API_REQUESTS, API_URL } from "../../constents/helpers";
import * as Sentry from "@sentry/react";
import { sentryCustomCaptureException } from "../../utils/custom-capture-exception";

/* Login Functionality */
export const SET_USER_LOGIN = (payload) => async (dispatch) => {
  try {
    const { username, password } = payload;
    if (!username || !password) {
      dispatch({
        type: Types.AUTH_LOGIN_ERROR,
        authLoginError: `Please provide user information `,
      });
      return;
    }
    const authResponse = await Auth.loginWithUserNameAndPassword(payload);
    let { user_id, userData } = authResponse;
    dispatch({ type: Types.SET_TOKEN, user_id, userData });
    dispatch({
      type: Types.IS_USER_AUTHENTICATED,
      isUserAuthenticated: true,
      userId: user_id,
      userData,
    });
  } catch (error) {
    return dispatch({
      type: Types.AUTH_LOGIN_ERROR,
      authLoginError: error.message,
    });
  }
};

export const SET_LOGIN_AUTH_ERROR = (message) => (dispatch) => {
  dispatch({ type: Types.AUTH_LOGIN_ERROR, authLoginError: message });
};

export const SET_IS_USER_AUTHENTICATED = () => (dispatch) => {
  try {
    const authResponse = Auth.initialize();
    let { userId, isUserAuthenticated, userData } = authResponse;

    dispatch({
      type: Types.IS_USER_AUTHENTICATED,
      isUserAuthenticated: isUserAuthenticated,
      userId: userId,
      userData,
    });
  } catch (error) {}
};

export const UPDATE_USER_CONSENT_TRACKING = (userId) => async (dispatch) => {
  try {
    const response = await Axios.axios({
      method: "patch",
      url: `${API_URL}api/v1/user/${userId}`,
      data: {
        trackingConsent: true,
      },
    });
    if (response) {
      dispatch({
        type: Types.DOES_USER_CONSENT_TRACKING,
        trackingConsent: true,
      });
    }
  } catch (error) {
    dispatch({ type: Types.SAVE_PROFILE_ERROR, error });
  }
};

export const VERIFY_USER_EMAIL = (email, lang) => async (dispatch) => {
  const sentryPayload = {
    data: Buffer.from(email).toString("base64"),
    lang,
  };

  try {
    const response = await Axios.unauthenticatedAxios.get(
      `${API_URL}reset-password?email=${email}&lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.VERIFY_USER_EMAIL,
        userverifyed: response.data,
        email: email,
      });
    }
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra("requestPayload", sentryPayload);
      scope.setLevel("error");
      sentryCustomCaptureException(new Error("password reset verification failed"));
    });

    dispatch({
      type: Types.VERIFY_USER_EMAIL_ERROR,
      error: error.response.data.message,
    });
  }
};

export const RESEND_USER_EMAIL = (email, lang) => async (dispatch) => {
  const sentryPayload = {
    data: Buffer.from(email).toString("base64"),
    lang,
  };

  try {
    const response = await Axios.unauthenticatedAxios.get(
      `${API_URL}reset-password?email=${email}&lang=${lang}`
    );
    if (response) {
      dispatch({
        type: Types.RESET_USER_EMAIL,
        resendverifyed: response.data,
      });
    }
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setExtra("requestPayload", sentryPayload);
      scope.setLevel("error");
      sentryCustomCaptureException(new Error("password reset email resend failed"));
    });

    dispatch({
      type: Types.VERIFY_USER_EMAIL_ERROR,
      error: error.response.data.details,
    });
  }
};

export function LOGOUT() {
  return (dispatch) => {
    Auth.logout();
    dispatch({ type: Types.ON_LOGOUT });
  };
}

export function CLEAR_AUTH_DATA() {
  return (dispatch) => {
    dispatch({
      type: Types.CLEAR_AUTH_DATA,
    });
  };
}
