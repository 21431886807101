import {
  Card,
  CardBody,
  CardHeader,
  Input,
  FormGroup,
  Label,
  Button,
  Alert,
} from "reactstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { API_URL } from "../../constents/helpers";
import { connect } from "react-redux";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import showicon from "../../assets/eye.svg";
import Axios from "../../models/Axios";
import * as Joi from "joi";

const mapStateToProps = (state) => {
  return {
    adminData: state.Layout.adminData,
    lang: state.Layout.defaultLanguage,
  };
};

function Register(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const disableSubmitButton = useMemo(() => {
    if (
      companyName.length > 0 &&
      companyNumber.length > 0 &&
      phoneNumber.length > 0 &&
      email.length > 0
    ) {
      return false;
    }
    return true;
  }, [companyName, companyNumber, phoneNumber, email]);

  const { t } = useTranslation();
  const history = useHistory();

  const submitUserInfo = useCallback(
    (e) => {
      e.preventDefault();
      setIsLoading(true);

      Axios.unauthenticatedAxios
        .post(`${API_URL}register`, {
          businessName: companyName,
          businessNumber: companyNumber,
          phoneNumber,
          email,
          lang: props.lang,
        })
        .then((response) => {
          if (response.status === 200) {
            history.push("/register/success");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response?.data) {
            if (err.response.data.message) {
              setErrorMessage(err.response.data.message);
            } else if (err.response.data.title) {
              setErrorMessage(err.response.data.title);
            } else if (err.response.data.detail) {
              setErrorMessage(err.response.data.detail);
            } else {
              setErrorMessage("Server Error");
            }
          } else {
            setErrorMessage("Server Error");
          }
          setIsLoading(false);
        });
    },
    [
      setIsLoading,
      history,
      companyName,
      companyNumber,
      phoneNumber,
      email,
      props.lang,
    ]
  );

  return (
    <div className="user-register">
      <Card className="main-card">
        <CardHeader>
          <Link to="/" className="navbar-brand main-logo">
            {props.adminData && (
              <img
                src={
                  `${API_URL}var/assets` + props.adminData.desktopLogo.fullpath
                }
                alt="Kislev"
              />
            )}
          </Link>
        </CardHeader>
        <CardBody>
          <div className="main-form">
            <h2>{t("register.title")}</h2>
            <p>{t("register.description")}</p>
            <form onSubmit={submitUserInfo}>
              <FormGroup>
                <div className="form-label-group">
                  <Input
                    type="text"
                    id="companyName"
                    placeholder={t("register.companyName")}
                    name="companyName"
                    value={companyName}
                    required
                    autoFocus
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                  <Label for="companyName">{t("register.companyName")}</Label>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <Input
                    type="text"
                    id="companyNumber"
                    placeholder={t("register.companyNumber")}
                    name="companyNumber"
                    value={companyNumber}
                    required
                    autoFocus
                    onChange={(e) => {
                      setCompanyNumber(e.target.value);
                    }}
                  />
                  <Label for="companyNumber">
                    {t("register.companyNumber")}
                  </Label>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <Input
                    type="tel"
                    id="phoneNumber"
                    placeholder={t("register.phoneNumber")}
                    name="phoneNumber"
                    value={phoneNumber}
                    required
                    autoFocus
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                  <Label for="phoneNumber">{t("register.phoneNumber")}</Label>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <Input
                    type="text"
                    id="email"
                    placeholder={t("register.email")}
                    name="email"
                    value={email}
                    required
                    invalid={
                      email.length > 0 &&
                      Joi.string().email({ tlds: false }).validate(email)
                        .error !== undefined
                    }
                    autoFocus
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <Label for="email">{t("register.email")}</Label>
                </div>
              </FormGroup>
              <FormGroup className="text-center">
                <Button
                  color="primary"
                  className="login-btn"
                  disabled={
                    disableSubmitButton ||
                    (email.length > 0 &&
                      Joi.string().email({ tlds: false }).validate(email)
                        .error !== undefined) ||
                    isLoading
                  }
                >
                  {t("feedbackModal.cta")}
                  {/* {adminData && adminData.loginButtonLabel} */}
                </Button>
              </FormGroup>
              <FormGroup>
                <div className="text-center">
                  {t("register.alreadyHaveAnAccount")}{" "}
                  <Link to="/home" className="text-dark text-underline">
                    {t("register.login")}
                  </Link>
                </div>
              </FormGroup>
            </form>
          </div>
        </CardBody>
      </Card>
      {errorMessage.length > 0 && <Alert color="danger">{errorMessage}</Alert>}
      {successMessage.length > 0 && (
        <Alert color="info">{successMessage}</Alert>
      )}
    </div>
  );
}

export default connect(mapStateToProps)(Register);
