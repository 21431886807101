import React from "react";
import { Modal, ModalBody } from "reactstrap";

import classes from "./CallModal.module.css";
import CallIcon from "../../assets/icon-call.svg";
import { withTranslation } from "react-i18next";

const CallModal = (props) => {
  const { adminData, t } = props;

  return (
    <>
      <Modal
        isOpen={props.callModalToggle}
        size="xl"
        className="product-details"
      >
        <div
          className="modal-close"
          onClick={() => {
            props.callModalClose();
          }}
        >
          <span className="icon-close"></span>
        </div>
        <ModalBody
          className={classes.ModalBody}
          style={{
            marginTop: "1rem",
            paddingTop: "120px",
            paddingBottom: "120px",
          }}
        >
          <p className={classes.ModalTitle}>
            {/* {t('callModal.title1')} */}
            {adminData && adminData.customerSupportTitle}
          </p>
          <p className={classes.ModalTitle}>
            {/* {t('callModal.title2')} */}
            {adminData && adminData.customerSupportSubTitle}
          </p>
          <p className={classes.ModalSubTitle} style={{ marginTop: "16px" }}>
            {/* {t('callModal.subTitle')} */}
            <span
              dangerouslySetInnerHTML={{
                __html: adminData && adminData.details,
              }}
            ></span>
          </p>
          <div dir="ltr" className={classes.ModalCallTextWrapper}>
            <img src={CallIcon} alt="" style={{ marginRight: "4px" }} />
            <p className={classes.ModalCallText}>
              {adminData && adminData.phoneNumber}
            </p>
          </div>
          <span className="mt-3">
            {/* Or */}
            {t("callModal.or")}
          </span>
          <div className="mt-3">
            <a
              href={`tel:${adminData && adminData.phoneNumber}`}
              className="rounded-pill font-weight-bold btn btn-primary"
              style={{ padding: "0.2rem 5rem" }}
            >
              {/* Call Now */}
              {t("callModal.call")}
            </a>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withTranslation()(CallModal);
