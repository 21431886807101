import React, { Fragment } from "react";
import { Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../Layout/Loader";
import { withTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../../store/actions/layout";
import { Select, Space } from "antd";
import EnFlag from "../../assets/flags/en-flag.svg";
import HeFlag from "../../assets/flags/he-flag.svg";
const { Option } = Select;
const mapStateToProps = (state) => {
  return {
    defaultLanguage: state.Layout.defaultLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    DEFAULT_LANGUAGE: (payload) => dispatch(DEFAULT_LANGUAGE(payload)),
  };
};

class LanguageChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  handalKeypress = (e) => {
    if (e.key === "Enter") {
      this.onSearch();
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  langChange = (e) => {
    const { value } = e.target;
    this.props.changeLanguage(value);
    this.props.DEFAULT_LANGUAGE(value);
  };
  langChanges = (value) => {
    this.props.changeLanguage(value);
    this.props.DEFAULT_LANGUAGE(value);
  };
  render() {
    const { defaultLanguage } = this.props;
    return (
      <Fragment>
        {this.state.isLoading && <Loader />}
        {this.props.isUserAuthenticated ? (
          <div>
            <Select
              className="select-langs"
              placeholder="select one country"
              defaultValue={defaultLanguage}
              onChange={this.langChanges}
              optionLabelProp="label"
              suffixIcon={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5999 7.45831L11.1666 12.8916C10.5249 13.5333 9.4749 13.5333 8.83324 12.8916L3.3999 7.45831"
                    stroke="#ADA9A0"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              popupClassName="select-lang-popup"
            >
              <Option
                value="en"
                label={
                  <Fragment>
                    <span role="img" aria-label="en">
                      <img src={EnFlag} />
                    </span>
                    EN
                  </Fragment>
                }
              >
                <Space>
                  <span role="img" aria-label="en">
                    <img src={EnFlag} />
                  </span>
                  english
                </Space>
              </Option>
              <Option
                value="he"
                label={
                  <Fragment>
                    <span role="img" aria-label="he">
                      <img src={HeFlag} />
                    </span>
                    HE
                  </Fragment>
                }
              >
                <Space>
                  <span role="img" aria-label="he">
                    <img src={HeFlag} />
                  </span>
                  Hebrew
                </Space>
              </Option>
              {/* <Option
               value="de"
               label={
                 <Fragment>
                   <span role="img" aria-label="de">
                     <img src={DeFlag} />
                   </span>
                   DE
                 </Fragment>
               }
             >
               <Space>
                 <span role="img" aria-label="de">
                   <img src={DeFlag} />
                 </span>
                 German
               </Space>
             </Option> */}
            </Select>
          </div>
        ) : (
          <select
            className="lang select-css-logout"
            style={{
              border: "none",
              background: "transparent",
              fontWeight: 700,
              color: "#000",
              paddingLeft: 0,
              lineHeight: 1.5,
            }}
            onChange={this.langChange}
            value={defaultLanguage}
          >
            <option value="en">EN</option>
            <option value="he">HE</option>
          </select>
        )}
      </Fragment>
    );
  }
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LanguageChange))
);
